import React, { useState } from 'react';
import * as moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import styles from './TimeOffCalendar.module.scss';
import TimeOffDetailsModal from '../TimeOffDetailsModal/TimeOffDetailsModal';

const TimeoffCalendar = ({ timeOff, handleDatesSet }) => {
  const dateFormat = 'DD/MM/YYYY';
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTimeOff, selectTimeOff] = useState({});

  const closeModal = () => {
    setModalOpen(false);
  };

  const modal = (
    <TimeOffDetailsModal
      timeOff={selectedTimeOff}
      open={modalOpen}
      onClose={closeModal}
    />
  );

  const eventColors = {
    timeOff: '#3788d8',
    holiday: '#FF7F50',
    hovered: '#809fff',
  };

  const eventIdMapping = timeOff.reduce((map, event) => {
    map[event.id] = event;
    return map;
  }, {});

  const events = timeOff.map((event) => {
    return {
      title: event.name,
      start: moment(event.startsAt, dateFormat).format(moment.HTML5_FMT.DATE),
      end: moment(event.endsAt, dateFormat)
        .add(1, 'd')
        .format(moment.HTML5_FMT.DATE),
      groupId: event.id,
      editable: false,
      eventType: event.eventType,
      backgroundColor:
        event.eventType === 'holiday'
          ? eventColors.holiday
          : eventColors.timeOff,
    };
  });

  const handleEventMouseIn = (info) => {
    const event = eventIdMapping[info.event.groupId];
    info.event.setProp('backgroundColor', eventColors.hovered);
    const title = `${event.name} - ${
      event.eventType === 'holiday' ? 'Legal holiday' : event.approvalDisplay
    }`;
    info.event.setProp('title', title);

    tippy(info.el, {
      content: title,
    });
  };

  const handleEventMouseOut = (info) => {
    const event = eventIdMapping[info.event.groupId];
    const color =
      event.eventType === 'holiday' ? eventColors.holiday : eventColors.timeOff;
    info.event.setProp('backgroundColor', color);
    const title = event.name;
    info.event.setProp('title', title);
  };

  const handleEventClick = (info) => {
    selectTimeOff(eventIdMapping[info.event.groupId]);
    setModalOpen(true);
  };


  return (
    <>
      {modal}
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin]}
        initialView="dayGridMonth"
        handleWindowResize
        headerToolbar={{
          left: 'dayGridMonth,timeGridWeek',
          center: 'title',
          right: 'prev,next',
        }}
        datesSet={handleDatesSet}
        events={events}
        eventMouseEnter={handleEventMouseIn}
        eventMouseLeave={handleEventMouseOut}
        eventClick={handleEventClick}
        eventClassNames={styles.event}
      />
    </>
  );
};

TimeoffCalendar.propTypes = {};

TimeoffCalendar.defaultProps = {};

export default TimeoffCalendar;

import React, { useState } from 'react';
import { TextField, Button } from '@material-ui/core';
import styles from './LoginForm.module.scss';
import { validateEmail } from '../../../../utils/validators';
import { signIn } from '../../../../services/api/api';
import FormErrors from '../../../common/FormErrors/FormErrors';

const LoginForm = ({ successCallback }) => {
  const [apiErrors, setApiErrors] = useState({});
  const [emailValue, setEmailValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [emailIsTouched, setEmailIsTouched] = useState(false);
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [passwordIsTouched, setPasswordIsTouched] = useState(false);
  const makeRequest = signIn();

  const handleSubmit = (event) => {
    setApiErrors({});
    event.preventDefault();
    makeRequest({
      email: emailValue,
      password: passwordValue,
    })
      .then((response) => successCallback(response))
      .catch((errors) => setApiErrors(errors));
  };

  const handleEmailChange = (event) => {
    setEmailValue(event.target.value);
    setEmailIsValid(validateEmail(event.target.value));
    setEmailIsTouched(true);
  };

  const handlePasswordChange = (event) => {
    setPasswordValue(event.target.value);
    setPasswordIsTouched(true);
    setPasswordIsValid(event.target.value !== '');
  };

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <TextField
        id="email"
        label="Email"
        type="email"
        variant="outlined"
        margin="normal"
        classes={{ root: styles.textInput }}
        onChange={handleEmailChange}
        value={emailValue}
        error={emailIsTouched && !emailIsValid}
        autoComplete="username"
      />
      <TextField
        id="password"
        label="Password"
        type="password"
        variant="outlined"
        margin="normal"
        classes={{ root: styles.textInput }}
        onChange={handlePasswordChange}
        value={passwordValue}
        error={passwordIsTouched && !passwordIsValid}
        autoComplete="current-password"
      />
      <div className={styles.buttonContainer}>
        <FormErrors apiErrors={apiErrors} />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={styles.logInButton}
          disabled={
            !(
              emailIsValid &&
              passwordIsValid &&
              emailIsTouched &&
              passwordIsTouched
            )
          }
        >
          Log In
        </Button>
      </div>
    </form>
  );
};

export default LoginForm;

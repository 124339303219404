import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { Redirect, useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import styles from './EmailConfirmation.module.scss';
import { DASHBOARD_PATH, LOGIN_PATH } from '../../../routing/paths';
import { confirmEmail } from '../../../services/api/api';
import LoadingIndicator from '../../common/LoadingIndicator/LoadingIndicator';
import CustomCard from '../../common/CustomCard/CustomCard';
import { useSession } from '../../../utils/hooks';

const EmailConfirmation = () => {
  const [confirmed, setConfirmed] = useState(false);
  const [error, setError] = useState(false);
  const history = useHistory();
  const { session } = useSession();
  const { token } = queryString.parse(history.location.search);

  useEffect(() => {
    if (token && !session) {
      const makeRequest = confirmEmail(token);
      makeRequest()
        .then(() => setConfirmed(true))
        .catch(() => setError(true));
    }
  });

  if (session || !token) {
    return <Redirect to={DASHBOARD_PATH} />;
  }

  if (!error && !confirmed) {
    return <LoadingIndicator />;
  }

  let cardTitle;
  let cardContent;

  if (error) {
    cardTitle = <div>Ooops</div>;
    cardContent = <div>The token you provided is invalid.</div>;
  }

  const redirectToLogin = () => history.replace(LOGIN_PATH);
  if (confirmed) {
    cardTitle = <div>Congratulations!</div>;
    cardContent = (
      <div>
        <p>Your registration is now completed.</p>
        <p>
          You are now a member of RaptorHR. Click on the button below to go to
          the login page and begin our journey together.
        </p>
        <div className={styles.container}>
          <Button onClick={redirectToLogin} variant="contained" color="primary">
            Go to login
          </Button>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <CustomCard title={cardTitle} content={cardContent} height="auto" />
    </div>
  );
};

export default EmailConfirmation;

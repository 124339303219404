import React, { useEffect, useState } from 'react';
import {
  Tabs,
  Tab,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TableSortLabel,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useHistory } from 'react-router-dom';
import styles from './EmployeeTable.module.scss';
import Avatar from '../../../common/Avatar/Avatar';
import { makeEmployeeDetailsPath } from '../../../../routing/paths';

const EmployeeTable = ({ company, employees }) => {
  const [selectedTab, setSelectedTab] = useState('1');
  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState('asc');
  const [selectedEmployees, setSelectedEmployees] = useState(employees);
  const history = useHistory();

  const employeesToShow = selectedEmployees.length
    ? selectedEmployees
    : employees;

  const statusesMapping = {
    2: 'Active',
    3: 'Invited',
    4: 'Deactivated',
  };

  const columns = [
    { id: 'avatar', label: '' },
    { id: 'name', label: 'Name' },
    { id: 'jobTitle', label: 'Job Title' },
    { id: 'status', label: 'Status' },
    { id: 'visitProfile', label: '' },
  ];

  const rows = employeesToShow.map((employee) => {
    return {
      id: employee.id,
      name: employee.fullName,
      jobTitle: employee.jobTitle,
      status: employee.status,
      avatar: <Avatar userProfile={employee} />,
      employee,
    };
  });
  const handleRowClick = (employee) => () => {
    history.push(makeEmployeeDetailsPath(company.id, employee.id));
  };

  const sort = (array) => {
    return array.sort((a, b) => {
      if (a[orderBy] || b[orderBy]) {
        return (
          a[orderBy].localeCompare(b[orderBy]) * (order === 'asc' ? 1 : -1)
        );
      }
      return 1;
    });
  };

  const handleSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleSort(event, property);
  };

  const handleTabSelect = (event, newValue) => {
    const selected =
      newValue === '1'
        ? employees
        : employees.filter(
            (employee) => employee.status === statusesMapping[newValue]
          );
    setSelectedEmployees(selected);
    setSelectedTab(newValue);
  };

  const tableRows = sort(rows).map((row) => (
    <TableRow
      classes={{ root: styles.tableRow }}
      hover
      onClick={handleRowClick(row.employee)}
      key={row.id}
    >
      <TableCell>{row.avatar}</TableCell>
      <TableCell>{row.name}</TableCell>
      <TableCell>{row.jobTitle}</TableCell>
      <TableCell>{row.status}</TableCell>
      <TableCell>
        <ChevronRightIcon />
      </TableCell>
    </TableRow>
  ));

  return (
    <div className={styles.tableRoot}>
      <div className={styles.tabsContainer}>
        <Tabs
          onChange={handleTabSelect}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          value={selectedTab}
          className={styles.tabSelector}
        >
          <Tab classes={{ root: styles.tab }} label="Active" value="2" />
          <Tab classes={{ root: styles.tab }} label="Invited" value="3" />
          <Tab classes={{ root: styles.tab }} label="Deactivated" value="4" />
          <Tab classes={{ root: styles.tab }} label="All" value="1" />
        </Tabs>
      </div>
      <div className={styles.tableContainer}>
        <Table>
          <TableHead>
            <TableRow classes={{ root: styles.tableHeader }}>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  {column.label ? (
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={createSortHandler(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  ) : (
                    column.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{tableRows}</TableBody>
        </Table>
      </div>
    </div>
  );
};

export default EmployeeTable;

import { createSlice } from '@reduxjs/toolkit';

export const sessionSlice = createSlice({
  name: 'session',
  initialState: '',
  reducers: {
    updateSession: (state, action) => {
      state = action.payload;
      return state;
    },
    clearSession: (state) => {
      state = '';
      return state;
    },
  },
});

export const { updateSession, clearSession } = sessionSlice.actions;

export const sessionSelector = (state) => state.session;

export default sessionSlice.reducer;

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import userProfile from './slices/userProfile';
import accessToken from './slices/accessToken';
import refreshToken from './slices/refreshToken';
import company from './slices/company';
import employees from './slices/employees';
import userTimeOff from './slices/userTimeOff';
import network from './slices/network';
import session from './slices/session';
import choices from './slices/choices';

const reducers = combineReducers({
  userProfile,
  company,
  employees,
  accessToken,
  refreshToken,
  userTimeOff,
  network,
  session,
  choices,
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['session', 'network', 'company'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);

export default store;

import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LoadingIndicator from '../components/common/LoadingIndicator/LoadingIndicator';
import ErrorPage from '../components/pages/ErrorPage/ErrorPage';
import { LOGIN_PATH } from '../routing/paths';
import {
  errorSelector,
  loadingSelector,
} from '../services/redux/slices/network';
import { checkObjectIsEmpty } from '../utils/misc';

const withFetch = (
  WrappedComponent,
  fetchAction,
  propName,
  needsAuthorization,
  selectors
) => ({ ...props }) => {
  const dispatch = useDispatch();
  const data = useSelector(selectors.successSelector || (() => {}));
  const error = useSelector(errorSelector);
  const loading = useSelector(loadingSelector);
  const history = useHistory();
  const beganFetch = useRef(false);

  useEffect(() => {
    dispatch(fetchAction());
    beganFetch.current = true;
    return () => {};
  }, []);

  const propsToSend = { ...props };
  propsToSend[propName] = data;

  if (error) {
    if (beganFetch.current && error.status === 401) {
      history.push({
        pathname: LOGIN_PATH,
        search: 'redirect=false',
      });
    }
    return <ErrorPage />;
  }

  if (loading || checkObjectIsEmpty(data)) {
    return <LoadingIndicator />;
  }
  return <WrappedComponent {...propsToSend} />;
};

export default withFetch;

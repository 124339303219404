import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  Redirect,
  withRouter,
  BrowserRouter as Router,
} from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useCookies } from 'react-cookie';
import { useInterval, useSession } from '../../utils/hooks';
import { makeRefreshToken } from '../../services/api/api';
import styles from './App.module.scss';
import Sidebar from '../common/Sidebar/Sidebar';
import PROTECTED_ROUTES, { UNPROTECTED_ROUTES } from '../../routing/routes';
import { DASHBOARD_PATH } from '../../routing/paths';
import ProtectedRoute from '../common/ProtectedRoute/ProtectedRoute';

function App() {
  const [, setCookie] = useCookies(['no-cookie']);
  const { storeSession } = useSession(setCookie);

  const tokenRefresh = makeRefreshToken(storeSession);

  useInterval(() => {
    tokenRefresh();
  }, 10000);

  useEffect(() => {
    document.title = 'RaptorHR';
  }, []);

  return (
    <div className={styles.root}>
      <CssBaseline />
      <Router>
        <div className={styles.content}>
          <Switch>
            {UNPROTECTED_ROUTES.map((route) => (
              <Route
                exact={route.path !== '*'}
                key={route.path}
                path={route.path}
                children={({ location }) => (
                  <div className={styles.unprotectedContent}>
                    <route.component location={location} />
                  </div>
                )}
              />
            ))}

            {PROTECTED_ROUTES.map((route) => (
              <ProtectedRoute
                exact={route.path !== '*'}
                key={route.path}
                path={route.path}
                sidebar={Sidebar}
              >
                {route.path === '*' ? (
                  <Redirect to={DASHBOARD_PATH} />
                ) : (
                  <div className={styles.protectedContent}>
                    <Sidebar />
                    <route.component />
                  </div>
                )}
              </ProtectedRoute>
            ))}
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;

import { createSlice } from '@reduxjs/toolkit';

export const refreshTokenSlice = createSlice({
  name: 'refreshToken',
  initialState: '',
  reducers: {
    updateRefreshToken: (state, action) => {
      state = action.payload;
      return state || '';
    },
    deleteRefreshToken: (state) => {
      state = '';
      return state;
    },
  },
});

export const {
  updateRefreshToken,
  deleteRefreshToken,
} = refreshTokenSlice.actions;
export const refreshTokenSelector = (state) => state.refreshToken;
export default refreshTokenSlice.reducer;

import { Button, FormGroup, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { REQUIRED_FIELD_ERROR } from '../../../../constants/js-constants';
import FormErrors from '../../../common/FormErrors/FormErrors';
import styles from './SignUpForm.module.scss';

const Signup = ({ makeRequest, setRegistrationFinished }) => {
  const [apiErrors, setApiErrors] = useState({});
  const formik = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required(REQUIRED_FIELD_ERROR),
      email: Yup.string().required('REQUIRED_FIELD_ERROR'),
      password: Yup.string().required('REQUIRED_FIELD_ERROR'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords do not match')
        .required(),
    }),
    onSubmit: (value) => {
      makeRequest(value)
        .then(() => {
          setRegistrationFinished(true);
        })
        .catch((err) => {
          setApiErrors(err);
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormGroup>
        <div>
          <TextField
            id="fullName"
            label="Name"
            type="text"
            variant="outlined"
            margin="normal"
            autoComplete="name"
            fullWidth
            clasess={{ root: styles.fullLengthField }}
            onChange={formik.handleChange}
            value={formik.values.fullName}
            onBlur={formik.onBlur}
          />
        </div>
      </FormGroup>
      <FormGroup>
        <div>
          <TextField
            id="email"
            label="Email"
            type="text"
            variant="outlined"
            margin="normal"
            autoComplete="email"
            fullWidth
            clasess={{ root: styles.fullLengthField }}
            onChange={formik.handleChange}
            value={formik.values.email}
            onBlur={formik.onBlur}
          />
          <FormErrors formik={formik} fieldName="email" apiErrors={apiErrors} />
        </div>
      </FormGroup>
      <FormGroup>
        <div>
          <TextField
            id="password"
            label="Password"
            type="password"
            variant="outlined"
            margin="normal"
            autoComplete="new-password"
            fullWidth
            clasess={{ root: styles.fullLengthField }}
            onChange={formik.handleChange}
            value={formik.values.password}
            onBlur={formik.onBlur}
          />
          <FormErrors
            formik={formik}
            fieldName="password"
            apiErrors={apiErrors}
          />
        </div>
        <div>
          <TextField
            id="confirmPassword"
            label="Confirm password"
            type="password"
            variant="outlined"
            margin="normal"
            autoComplete="new-password"
            fullWidth
            clasess={{ root: styles.fullLengthField }}
            onChange={formik.handleChange}
            value={formik.values.confirmPassword}
            onBlur={formik.onBlur}
          />
          <FormErrors
            formik={formik}
            fieldName="confirmPassword"
            apiErrors={apiErrors}
          />
        </div>
      </FormGroup>
      <div className={styles.buttonContainer}>
        <Button type="submit" variant="contained" color="primary">
          Create Account
        </Button>
      </div>
    </form>
  );
};

Signup.defaultProps = {};

export default Signup;

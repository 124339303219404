import React, { useState } from 'react';
import queryString from 'query-string';
import { Redirect, useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import styles from './JoinCompany.module.scss';
import { DASHBOARD_PATH, LOGIN_PATH } from '../../../routing/paths';
import CustomCard from '../../common/CustomCard/CustomCard';
import { useSession } from '../../../utils/hooks';
import { joinCompany } from '../../../services/api/api';

const JoinCompany = () => {
  const history = useHistory();
  const { session } = useSession();
  const { token } = queryString.parse(history.location.search);
  const [apiErrors, setApiErrors] = useState({});
  const [completed, setCompleted] = useState(false);
  const makeRequest = joinCompany();

  if (session || !token) {
    return <Redirect to={DASHBOARD_PATH} />;
  }

  const join = () =>
    makeRequest({ token })
      .then(() => setCompleted(true))
      .catch((error) => setApiErrors(error));

  let cardTitle;
  let cardContent;

  if (completed) {
    const redirectToLogin = () => history.replace(LOGIN_PATH);
    cardTitle = 'Registration completed';
    cardContent = (
      <>
        <p>Congratulations! You are now a member of your new company.</p>
        <p>Click the button below to log in and start using the app.</p>
        <div className={styles.buttonContainer}>
          <Button onClick={redirectToLogin} variant="contained" color="primary">
            Go to login
          </Button>
        </div>
      </>
    );
  } else {
    cardTitle = 'Join us';
    cardContent = (
      <>
        <p>You have been invited to join a new company.</p>
        <p>
          <b>Warning &nbsp;</b>By clicking the button bellow you will be removed
          from any company you are currently enrolled to.
        </p>
        <div className={styles.buttonContainer}>
          <Button onClick={join} variant="contained" color="primary">
            Join
          </Button>
        </div>
      </>
    );
  }

  return (
    <div className={styles.container}>
      <CustomCard title={cardTitle} content={cardContent} height="auto" />
    </div>
  );
};

export default JoinCompany;

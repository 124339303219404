import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './CreateCompany.module.scss';
import withCreateCompanyChoices from '../../../containers/withCreateCompanyChoices';
import CreateCompanyForm from './CreateCompanyForm/CreateCompanyForm';
import CustomCard from '../../common/CustomCard/CustomCard';

const CreateCompany = ({ companySizeChoices, countryChoices }) => {
  const cardTitle = 'Add a company';
  const history = useHistory();
  const cardContent = (
    <CreateCompanyForm
      countryChoices={countryChoices}
      companySizeChoices={companySizeChoices}
      history={history}
    />
  );

  return (
    <div className={styles.container}>
      <CustomCard title={cardTitle} content={cardContent} />
    </div>
  );
};

export default withCreateCompanyChoices(CreateCompany);

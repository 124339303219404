import { createSlice } from '@reduxjs/toolkit';

export const networkSlice = createSlice({
  name: 'network',
  initialState: { loading: false, error: null },
  reducers: {
    setNetworkLoading: () => {
      return { loading: true, error: null };
    },
    setNetworkFinished: () => {
      return { loading: false, error: null };
    },
    setNetworkError: (state, action) => {
      return { loading: false, error: action.payload };
    },
  },
});

export const {
  setNetworkLoading,
  setNetworkError,
  setNetworkFinished,
} = networkSlice.actions;

export const loadingSelector = (state) => state.network.loading;
export const errorSelector = (state) => state.network.error;

export default networkSlice.reducer;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import styles from './Dashboard.module.scss';
import NoCompanyFound from '../NoCompanyFound/NoCompanyFound';
import { userProfileSelector } from '../../../services/redux/slices/userProfile';
import withUserProfile from '../../../containers/withUserProfile';
import { fetchEvents } from '../../../services/api/api';
import { selectedCompanySelector } from '../../../services/redux/slices/company';
import EventCard from './EventCard/EventCard';
import { checkObjectIsEmpty } from '../../../utils/misc';
import LoadingIndicator from '../../common/LoadingIndicator/LoadingIndicator';
import { MOMENT_DATE_FORMAT } from '../../../constants/js-constants';
import TimeOffListItem from '../../common/TimeOffListItem/TimeOffListItem';
import { makeTimeOffApprovalPath } from '../../../routing/paths';

const Dashboard = () => {
  const history = useHistory();
  const userProfile = useSelector(userProfileSelector);
  const storedSelectedCompany = useSelector(selectedCompanySelector);
  const selectedCompany = storedSelectedCompany || userProfile.companies[0];
  const [events, setEvents] = useState({});

  useEffect(() => {
    if (selectedCompany && !checkObjectIsEmpty(selectedCompany)) {
      const makeFetchEventsRequest = fetchEvents(selectedCompany.id, history);
      makeFetchEventsRequest().then((data) => setEvents(data));
    }
  }, []);

  const formatDate = (date) => moment(date, MOMENT_DATE_FORMAT, true);

  const goToTimeOffApproval = (timeOffId) => () =>
    history.push(makeTimeOffApprovalPath(selectedCompany.id, timeOffId));

  if (!userProfile.companies.length) {
    return <NoCompanyFound />;
  }

  if (checkObjectIsEmpty(events)) {
    return <LoadingIndicator />;
  }

  const welcomeCard = (
    <>
      <div className={styles.welcomeCard}>
        <h3>Welcome {userProfile.fullName.split(' ')[0]}!</h3>
        <div>Remaining days: { events && events.remainingTimeOff }</div>
      </div>
    </>
  );

  const activitiesCard = (
    <div className={styles.activitiesContainer}>
      {events.timeOffApprovals.length ? (
        events.timeOffApprovals.map((timeOff) => (
          <div
            className={styles.activity}
            role="button"
            tabIndex={0}
            onClick={goToTimeOffApproval(timeOff.id)}
            onKeyDown={goToTimeOffApproval(timeOff.id)}
            key={timeOff.id}
          >
            <div className={styles.activityContent}>
              Approve time off for {timeOff.userFullName}
              <ChevronRightIcon />
            </div>
          </div>
        ))
      ) : (
        <div className={styles.missingData}>
          Congratulations! You have no more activities for now.
        </div>
      )}
    </div>
  );

  const upcomingTimeOff = (
    <div>
      {events.upcomingTimeOff.length ? (
        events.upcomingTimeOff.map((timeOff) => (
          <TimeOffListItem timeOff={timeOff} key={timeOff.id} unclickable />
        ))
      ) : (
        <div className={styles.missingData}>
          No upcoming time off in your company.
        </div>
      )}
    </div>
  );

  const upcomingEvents = (
    <>
      {events.upcomingEvents.length ? (
        events.upcomingEvents.map((birthday) => {
          const date = formatDate(birthday.date);
          const birthDayData = {
            ...birthday,
            startsAt: date,
            endsAt: date,
            userFullName: `${birthday.user}'s birthday`,
          };
          return (
            <TimeOffListItem timeOff={birthDayData} hideDuration unclickable />
          );
        })
      ) : (
        <div className={styles.missingData}>You have no events coming up.</div>
      )}
    </>
  );

  const upcomingHolidays = (
    <>
      {events.upcomingHolidays.length ? (
        events.upcomingHolidays.map((holiday) => {
          const holidayData = {
            ...holiday,
            startsAt: holiday.startsAt,
            endsAt: holiday.endsAt,
          };
          return (
            <TimeOffListItem
              key={holidayData.startsAt}
              timeOff={holidayData}
              unclickable
            />
          );
        })
      ) : (
        <div className={styles.missingData}>
          There are no holidays coming up
        </div>
      )}
    </>
  );

  return (
    <div className={styles.root}>
      <div className={styles.left}>
        <EventCard>{welcomeCard}</EventCard>

        <EventCard title="Upcoming Time off" color="red">
          {upcomingTimeOff}
        </EventCard>

        <EventCard title="Upcoming Events" color="orange">
          {upcomingEvents}
        </EventCard>

        <EventCard title="Upcoming Holidays" color="yellow">
          {upcomingHolidays}
        </EventCard>
      </div>
      <div className={styles.right}>
        <EventCard title="Pending Tasks" color="green" long>
          {activitiesCard}
        </EventCard>
      </div>
    </div>
  );
};

export default withUserProfile(Dashboard);

import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from './CompanyEmployees.module.scss';
import withEmployees from '../../../containers/withEmployees';
import CustomCard from '../../common/CustomCard/CustomCard';
import EmployeeTable from './EmployeeTable/EmployeeTable';
import CreateEmployeeForm from './CreateEmployeeForm/CreateEmployeeForm';
import Modal from '../../common/Modal/Modal';
import {
  createEmployee,
  fetchCompanyEmployees,
  fetchUserProfile,
} from '../../../services/api/api';
import { checkObjectIsEmpty } from '../../../utils/misc';
import { userProfileSelector } from '../../../services/redux/slices/userProfile';
import NoCompanyFound from '../NoCompanyFound/NoCompanyFound';
import LoadingIndicator from '../../common/LoadingIndicator/LoadingIndicator';
import {
  selectedCompanySelector,
  updateSelectedCompany,
} from '../../../services/redux/slices/company';

const CompanyEmployees = () => {
  const [createEmployeeModealOpen, setCreateEmployeeFormModalOpen] =
    useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const userProfile = useSelector(userProfileSelector);
  let selectedCompany = useSelector(selectedCompanySelector);

  const [employeesToDisplay, setEmployeesToDisplay] = useState([]);

  const handleModalOpen = () => setCreateEmployeeFormModalOpen(true);
  const handleModalClose = () => setCreateEmployeeFormModalOpen(false);
  const addEmployee = (employee) => {
    employeesToDisplay.push(employee);
    setEmployeesToDisplay(employeesToDisplay);
  };

  useEffect(() => {
    if (checkObjectIsEmpty(userProfile)) {
      dispatch(fetchUserProfile(history));
    }
    const company = selectedCompany && userProfile?.companies?.[0];
    if (!company) {
      return null;
    }
    const fetchEmployees = fetchCompanyEmployees(company.id, history);
    fetchEmployees().then((employees) => setEmployeesToDisplay(employees));
  }, []);

  if (checkObjectIsEmpty(userProfile)) {
    return <LoadingIndicator />;
  }

  if (!userProfile.companies.length) {
    return <NoCompanyFound />;
  }

  if (!selectedCompany) {
    dispatch(updateSelectedCompany(userProfile.companies[0]));
    [selectedCompany] = userProfile.companies;
  }

  const createEmployeeRequest = createEmployee(selectedCompany.id, history);

  const cardTitle = <div>Employees</div>;
  const cardActions = (
    <Button variant="contained" color="primary" onClick={handleModalOpen}>
      + Add employee
    </Button>
  );

  return (
    <>
      <CustomCard
        title={cardTitle}
        actions={cardActions}
        content={
          <EmployeeTable
            company={selectedCompany}
            employees={employeesToDisplay}
          />
        }
      />
      <Modal
        open={createEmployeeModealOpen}
        onClose={handleModalClose}
        title="Create employee"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={styles.modal}
      >
        <CreateEmployeeForm
          request={createEmployeeRequest}
          closeModal={handleModalClose}
          callback={addEmployee}
        />
      </Modal>
    </>
  );
};

export default CompanyEmployees;

import React, { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IconButton, MenuItem, MenuList, Popover } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import styles from './UserBadge.module.scss';
import Avatar from '../Avatar/Avatar';
import {
  PROFILE_PATH,
  LOGOUT_PATH,
  USER_SETTINGS_PATH,
} from '../../../routing/paths';

const dropdownMenuItems = [
  { text: 'My Profile', redirect: PROFILE_PATH },
  { text: 'Settings', redirect: USER_SETTINGS_PATH },
  { text: 'Logout', redirect: LOGOUT_PATH },
];

const UserBadge = ({ userProfile }) => {
  const history = useHistory();
  const [dropdownAnchor, setDropdownAnchor] = useState(null);
  const open = Boolean(dropdownAnchor);
  const id = open ? 'dropdown' : undefined;

  let { fullName } = userProfile;
  if (fullName.length > 9) {
    fullName = `${fullName.substr(0, 6)}...`;
  }

  const handleClick = (event) => {
    setDropdownAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setDropdownAnchor(null);
  };

  const handleSelect = (event) => {
    const { redirect } = event.currentTarget.dataset;
    handleClose();
    history.push(redirect);
  };

  return (
    <span className={styles.UserBadge}>
      <Avatar userProfile={userProfile} />
      <div className={styles.nameBox}>{fullName}</div>
      <IconButton
        className={open ? styles.arrowOpen : styles.arrowClosed}
        onClick={handleClick}
        aria-expanded={open}
        aria-label="show options"
      >
        <ExpandMoreIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={dropdownAnchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuList autoFocusItem={open} id="menu-list-grow">
          {dropdownMenuItems.map((item) => (
            <MenuItem
              onClick={handleSelect}
              data-redirect={item.redirect}
              key={item.text}
            >
              {item.text}
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
    </span>
  );
};

UserBadge.propTypes = {};

UserBadge.defaultProps = {};

export default UserBadge;

import { createSlice } from '@reduxjs/toolkit';

export const employeesSlice = createSlice({
  name: 'employees',
  initialState: null,
  reducers: {
    updateEmployees: (state, action) => {
      return {
        loading: false,
        error: null,
        data: action.payload,
      };
    },
    fetchEmployeesBegin: () => {
      return { loading: true, data: null, error: null };
    },
    fetchEmployeesFailure: (state, action) => {
      return { loading: false, error: action.payload, data: null };
    },
  },
});

export const {
  updateEmployees,
  fetchEmployeesBegin,
  fetchEmployeesFailure,
} = employeesSlice.actions;

export const employeesSelector = (state) => state.employees ? state.employees.data : {};
export const employeesErrorSelector = (state) => state.employees ? state.employees.error : {};
export const employeesLoadingSelector = (state) => state.employees ? state.employees.loading : {};

export default employeesSlice.reducer;

import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './CompanyCalendar.module.scss';
import TimeoffCalendar from './TimeOffCalendar/TimeOffCalendar';
import { fetchCompanyTimeOffInRange } from '../../../services/api/api';
import LoadingIndicator from '../../common/LoadingIndicator/LoadingIndicator';
import withUserProfile from '../../../containers/withUserProfile';
import NoCompanyFound from '../NoCompanyFound/NoCompanyFound';
import ErrorPage from '../ErrorPage/ErrorPage';
import {
  selectedCompanySelector,
  updateSelectedCompany,
} from '../../../services/redux/slices/company';
import CustomCard from '../../common/CustomCard/CustomCard';

const CompanyCalendar = ({ userProfile, history }) => {
  const [startDateRange, setStartDateRange] = useState(null);
  const [endDateRange, setEndDateRange] = useState(null);
  const [timeOff, setTimeOff] = useState([]);
  const [loading, setLoading] = useState(false);
  const [networkError, setNetworkError] = useState(null);
  const dispatch = useDispatch();
  let selectedCompany = useSelector(selectedCompanySelector);

  if (!userProfile.companies.length) {
    return <NoCompanyFound />;
  }

  if (!selectedCompany) {
    dispatch(updateSelectedCompany(userProfile.companies[0]));
    [selectedCompany] = userProfile.companies;
  }

  if (loading) {
    return <LoadingIndicator />;
  }
  if (networkError) {
    return <ErrorPage />;
  }

  const handleDatesSet = (event) => {

    const startDate = new Date(event.start);
    const endDate = new Date(event.end);

    startDate.setMonth(startDate.getMonth() );
    endDate.setMonth(endDate.getMonth());

    const formattedStartDate = startDate.toISOString().slice(0, 10);
    const formattedEndDate = endDate.toISOString().slice(0, 10);

    if (!selectedCompany || !selectedCompany.id) {
        console.log("no selected company")
        return;
    }
    if(startDateRange==null || endDateRange==null){
        const fetchTimeOff = fetchCompanyTimeOffInRange(selectedCompany.id, formattedStartDate, formattedEndDate, history)
        setLoading(true)
        fetchTimeOff()   
            .then((resp) => {
                setLoading(false);
                setTimeOff(resp);
                setStartDateRange(formattedStartDate)
                setEndDateRange(formattedEndDate)
            })
            .catch((error) => {
                setLoading(false);
                setNetworkError(error);
            });
    } else if (formattedStartDate < startDateRange){
      const fetchTimeOff = fetchCompanyTimeOffInRange(selectedCompany.id, formattedStartDate, startDateRange, history)
      fetchTimeOff()   
          .then((resp) => {
              const uniqueResp = resp.filter((item) => !timeOff.find((existingItem) => existingItem.id === item.id));
              setTimeOff([...timeOff, ...uniqueResp]);
              setStartDateRange(formattedStartDate)
          })
          .catch((error) => {
              setLoading(false);
              setNetworkError(error);
          });
    } else if (formattedEndDate > endDateRange){
      const fetchTimeOff = fetchCompanyTimeOffInRange(selectedCompany.id, endDateRange, formattedEndDate, history)
      fetchTimeOff()   
          .then((resp) => {
              const uniqueResp = resp.filter((item) => !timeOff.find((existingItem) => existingItem.id === item.id));
              setTimeOff([...timeOff, ...uniqueResp]);
              setEndDateRange(formattedEndDate)
          })
          .catch((error) => {
              setLoading(false);
              setNetworkError(error);
          });
    }


};

  return (
    <CustomCard
      title={selectedCompany.name}
      content={<TimeoffCalendar timeOff={timeOff} handleDatesSet={handleDatesSet}/>}
      height="80vh"
    />
  );
};

CompanyCalendar.propTypes = {};

CompanyCalendar.defaultProps = {};

export default withUserProfile(CompanyCalendar);

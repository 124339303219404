export const parseUserProfile = (response) => {
  return {
    id: response.id,
    email: response.email,
    companyId: response.company_id,
  };
};

export const baseUserProfileFielMapping = {
  id: 'id',
  fullName: 'full_name',
};

export const companyCreateFieldMapping = {
  name: 'name',
  size: 'number_of_employees',
  address: 'address',
  country: 'country',
  zipCode: 'zip_code',
  contactPhone: 'contact_phone',
  contactEmail: 'contact_email',
};

export const eventFieldMapping = {
  startsAt: 'starts_at',
  endsAt: 'ends_at',
  typeDisplay: 'type_display',
  type: 'type',
  legalHoliday: 'legal_holiday',
  id: 'id',
};

export const fetchCompanyFieldMapping = {
  id: 'id',
  name: 'name',
  description: 'description',
  countryDisplay: 'country_display',
  numberOfEmployees: 'number_of_employees',
  address: 'address',
  country: 'country',
  zipCode: 'zip_code',
  contactPhone: 'contact_phone',
  contactEmail: 'contact_email',
  canEdit: 'can_edit',
  timeOffDaysTotal: 'time_off_days_total',
  holidayNeedsApproval: 'holiday_needs_approval',
  sickLeaveNeedsApproval: 'sick_leave_needs_approval',
  maternityLeaveNeedsApproval: 'maternity_leave_needs_approval',
  specialLeaveNeedsApproval: 'special_leave_needs_approval',
  logo: 'logo',
  events: {
    key: 'events',
    mapping: eventFieldMapping,
  },
};

export const fileFieldMapping = {
  file: 'file',
};

export const employeeFieldMapping = {
  id: 'id',
  fullName: 'full_name',
  email: 'email',
  isActive: 'is_active',
  emailConfirmed: 'email_confirmed',
  status: 'status',
  jobTitle: 'job_title',
  reportsToMe: 'reports_to_me',
  inviteToApp: 'invite_to_app',
  deactivated: 'deactivated',
  timeOffDaysTotal: 'time_off_days_total',
  timeOffDaysRemaining: 'time_off_days_remaining',
  };

export const timeOffFieldMapping = {
  startsAt: 'starts_at',
  endsAt: 'ends_at',
  type: 'type',
  typeDisplay: 'type_display',
  description: 'description',
  duration: 'duration',
  additionalFiles: 'additional_files',
  canEdit: 'can_edit',
  id: 'id',
  isApproved: 'is_approved',
  isRejected: 'is_rejected',
};

export const trackerEntryFieldMapping = {
  id: 'id',
  startsAt: 'starts_at',
  endsAt: 'ends_at',
  description: 'description',
  userId: 'user_id',
  companyId: 'company_id',
};

export const userProfileCompanyFieldMapping = {
  jobTitle: 'job_title',
  useSignature: "use_signature",
};

export const userProfileFieldMapping = {
  id: 'id',
  email: 'email',
  companies: { key: 'companies', mapping: fetchCompanyFieldMapping },
  fullName: 'full_name',
  jobTitle: 'job_title',
  useSignature: "use_signature",
  status: 'status',
  birthDate: 'birth_date',
  address: 'address',
  dateJoined: 'date_joined',
  phoneNumber: 'phone_number',
  hideBirthDate: 'hide_birth_date',
  timeOffDaysTotal: 'time_off_days_total',
  timeOffDaysRemaining: 'time_off_days_remaining',
  timeOff: { key: 'time_off', mapping: timeOffFieldMapping },
  manager: { key: 'manager', mapping: baseUserProfileFielMapping },
  avatar: 'avatar',
  canEdit: 'can_edit',
  deactivated: 'deactivated',
  canEditTimeOff: 'can_edit_time_off',
  generateWord: 'generate_word_for_leave_request',
  isUserManager: 'is_user_manager', //used so only manager can set if for user are or aren't generated word documents is a boolean for the user that performs request not for the employee reponse
};

export const companyTimeOffFieldMapping = {
  user: 'user',
  startsAt: 'starts_at',
  endsAt: 'ends_at',
  type: 'type',
  isApproved: 'is_approved',
  name: 'name',
  id: 'id',
  duration: 'duration',
  approvalDisplay: 'approval_display',
  needsApproval: 'needs_approval',
  eventType: 'event_type',
  additionalFiles: 'additional_files',
  canEdit: 'can_edit',
  description: 'description',
};

export const timeOffDetailsFieldMapping = {
  id: 'id',
  userFullName: 'user_full_name',
  startsAt: 'starts_at',
  endsAt: 'ends_at',
  type: 'type',
  typeDisplay: 'type_display',
  duration: 'duration',
  description: 'description',
  isApproved: 'is_approved',
  isRejected: 'is_rejected',
  needsApproval: 'needs_approval',
  additionalFiles: 'additional_files',
  canEdit: 'can_edit',
};

export const signUpFieldMapping = {
  fullName: 'full_name',
  email: 'email',
  password: 'password',
  confirmPassword: 'confirm_password',
};

export const completeRegistrationFieldMapping = {
  password: 'password',
  confirmPassword: 'confirm_password',
  token: 'token',
};

export const eventsFieldMapping = {
  timeOffApprovals: {
    key: 'time_off_approvals',
    mapping: timeOffDetailsFieldMapping,
  },
  remainingTimeOff: 'remaining_time_off',
  upcomingEvents: {
    key: 'upcoming_events',
    mapping: {
      date: 'date',
      user: 'user',
      id: 'id',
    },
  },
  upcomingHolidays: {
    key: 'upcoming_holidays',
    mapping: timeOffDetailsFieldMapping,
  },
  upcomingTimeOff: {
    key: 'upcoming_time_off',
    mapping: timeOffDetailsFieldMapping,
  },
};

export const convertJStoJSON = (object, mapping) => {
  return Object.keys(object).reduce((convertedObject, key) => {
    const mappingForValue = mapping[key];
    if (typeof mappingForValue === 'object') {
      convertedObject[mappingForValue.key] = convertJStoJSON(
        object[key],
        mappingForValue.mapping
      );
    } else {
      convertedObject[mapping[key]] = object[key];
    }
    return convertedObject;
  }, {});
};

export const convertJsonToJS = (object, mapping) => {
  const reverseMap = Object.fromEntries(
    Object.entries(mapping).map((a) => {
      if (typeof a[1] === 'object') {
        return [a[0], a[1]];
      }
      return a.reverse();
    })
  );

  const convertObject = (jsonObject) =>
    Object.keys(reverseMap).reduce((convertedObject, key) => {
      const mappingForValue = reverseMap[key];
      const mappingKey = mappingForValue.key;
      if (
        typeof mappingForValue === 'object' &&
        mappingForValue !== null &&
        jsonObject[mappingKey]
      ) {
        convertedObject[key] = convertJsonToJS(
          jsonObject[mappingKey],
          mappingForValue.mapping
        );
      } else {
        convertedObject[reverseMap[key]] = jsonObject[key];
      }
      return convertedObject;
    }, {});

  let convertedData;
  if (Array.isArray(object)) {
    convertedData = object.map((element) => convertObject(element));
  } else {
    convertedData = convertObject(object);
  }

  return convertedData;
};

import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { format } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { Button, FormGroup, InputLabel } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import styles from './CreateEventModal.module.scss';
import { DATE_FORMAT } from '../../../../../constants/js-constants';
import FormErrors from '../../../../common/FormErrors/FormErrors';
import { timeOffFieldMapping } from '../../../../../utils/parsers';
import Modal from '../../../../common/Modal/Modal';
import FormInput from '../../../../common/FormInput/FormInput';
import { createCompanyEvent } from '../../../../../services/api/api';

const CreateEventModal = ({ companyId, open, onClose, addEvent }) => {
  const today = new Date();
  const history = useHistory();
  const [apiErrors, setApiErrors] = useState({});
  const makeRequest = createCompanyEvent(companyId, history);

  const formik = useFormik({
    initialValues: {
      startsAt: today,
      typeDisplay: '',
    },
    validationSchema: Yup.object({
      startsAt: Yup.string().required(),
      typeDisplay: Yup.string().required(),
    }),
    onSubmit: (value) => {
      const date = format(value.startsAt, DATE_FORMAT);
      const formattedValue = {
        ...value,
        startsAt: date,
        endsAt: date,
      };
      makeRequest(formattedValue)
        .then(() => {
          addEvent(formattedValue);
          onClose();
        })
        .catch((errors) => setApiErrors(errors));
    },
  });

  const handleStartsAtChange = (option) => {
    formik.setFieldValue('startsAt', option);
  };

  return (
    <Modal title="Add a new event" open={open} onClose={onClose}>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.inputContainer}>
          <FormInput
            type="text"
            fieldName="typeDisplay"
            label="Name"
            required
            fullLength
            formikObject={formik}
          />
          <FormErrors
            formik={formik}
            fieldName="typeDisplay"
            apiErrors={apiErrors}
            fieldMapping={timeOffFieldMapping}
          />
        </div>
        <div className={styles.inlineForm}>
          <FormGroup row className={styles.inlineFormInput}>
            <InputLabel className={styles.inputLabel}>Date</InputLabel>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                id="startsAt"
                format={DATE_FORMAT}
                variant="inline"
                value={formik.values.startsAt}
                onChange={handleStartsAtChange}
                onBlur={formik.handleBlur}
              />
            </MuiPickersUtilsProvider>
            <FormErrors
              formik={formik}
              fieldName="startsAt"
              apiErrors={apiErrors}
              fieldMapping={timeOffFieldMapping}
            />
          </FormGroup>
        </div>
        <FormErrors
          formik={formik}
          apiErrors={apiErrors}
          fieldMapping={timeOffFieldMapping}
        />
        <div className={styles.buttonContainer}>
          <Button type="submit" variant="contained" color="primary">
            Create Event
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default CreateEventModal;

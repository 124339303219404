import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import styles from './MyTimeoffManagement.module.scss';
import CustomCard from '../../common/CustomCard/CustomCard';
import Modal from '../../common/Modal/Modal';
import AddTimeoffForm from './AddTimeoffForm/AddTimeoffForm';
import EditTimeoffForm from './EditTimeoffForm/EditTimeoffForm';
import { userProfileSelector } from '../../../services/redux/slices/userProfile';
import {
  addTimeOff,
  editTimeOff,
  deleteTimeOff,
  fetchUserTimeOff,
} from '../../../services/api/api';
import TimeOffListItem from '../../common/TimeOffListItem/TimeOffListItem';
import NoCompanyFound from '../NoCompanyFound/NoCompanyFound';
import { selectedCompanySelector } from '../../../services/redux/slices/company';

const MyTimeoffManagement = () => {
  const [addTimeoffModalOpen, setTimeOffModalOpen] = useState(false);
  const [editTimeoffModalOpen, setEditTimeOffModalOpen] = useState(false);
  const [timeOff, setTimeOff] = useState([]);
  const [editablePeriod, setEditablePeriod] = useState({});
  const history = useHistory();
  const userProfile = useSelector(userProfileSelector);
  const storedSelectedCompany = useSelector(selectedCompanySelector);
  const selectedCompany = storedSelectedCompany || userProfile.companies[0];

  const makeEditTimeOffRequest = (period) =>
    editTimeOff(
      selectedCompany ? selectedCompany.id : null,
      period.id,
      history
    );
  const makeDeleteTimeOffRequest = (period) =>
    deleteTimeOff(
      selectedCompany ? selectedCompany.id : null,
      period.id,
      history
    );
  const addTimeOffRequest = addTimeOff(
    selectedCompany ? selectedCompany.id : null,
    userProfile.id,
    history
  );
  const fetchTimeOff = fetchUserTimeOff(
    selectedCompany ? selectedCompany.id : null,
    userProfile.id,
    history
  );

  useEffect(() => {
    if (selectedCompany) {
      fetchTimeOff().then((resp) => setTimeOff(resp));
    }
  }, []);

  if (!userProfile.companies.length) {
    return <NoCompanyFound />;
  }

  const handleModalOpen = () => setTimeOffModalOpen(true);
  const handleModalClose = () => {
    setEditablePeriod({});
    setTimeOffModalOpen(false);
  };
  const handleEditModalOpen = (period) => () => {
    setEditTimeOffModalOpen(true);
    setEditablePeriod(period);
  };
  const handleEditModalClose = () => {
    setEditTimeOffModalOpen(false);
  };

  const updateTimeOff = (updatedTimeOff) => {
    setTimeOff(
      timeOff.map((period) =>
        updatedTimeOff.id === period.id ? updatedTimeOff : period
      )
    );
  };

  const addNewTimeOff = (newTimeOff) => {
    timeOff.push(newTimeOff);
    setTimeOff(timeOff);
  };

  const handleTimeOffDelete = (period) => () => {
    const deleteTimeOffRequest = makeDeleteTimeOffRequest(period);
    deleteTimeOffRequest().then(() => {
      setTimeOff(
        timeOff.filter((timeOffPeriod) => timeOffPeriod.id !== period.id)
      );
    });
  };

  const cardTitle = <div>My Time Off</div>;
  const cardActions = (
    <Button variant="contained" color="primary" onClick={handleModalOpen}>
      + Add time off
    </Button>
  );

  const cardContent = timeOff.map((period) => (
    <div className={styles.periodContainer} key={period.startsAt}>
      <TimeOffListItem timeOff={period} />
      {!period.isApproved && !period.isRejected && (
        <div className={styles.actionsContainer}>
          <Button
            variant="contained"
            className={styles.edit}
            onClick={handleEditModalOpen(period)}
          >
            Edit <EditIcon />
          </Button>
          <Button
            variant="contained"
            className={styles.edit}
            onClick={handleTimeOffDelete(period)}
          >
            Delete <DeleteIcon />
          </Button>
        </div>
      )}
    </div>
  ));

  return (
    <>
      <CustomCard
        title={cardTitle}
        actions={cardActions}
        content={cardContent}
      />
      <Modal
        open={addTimeoffModalOpen}
        onClose={handleModalClose}
        title="Add Time Off"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <AddTimeoffForm
          closeModal={handleModalClose}
          initialValues={{}}
          submitRequest={addTimeOffRequest}
          updateTimeOff={addNewTimeOff}
        />
      </Modal>
      <Modal
        open={editTimeoffModalOpen}
        onClose={handleEditModalClose}
        title="Edit Time Off"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <EditTimeoffForm
          closeModal={handleEditModalClose}
          initialValues={{ ...editablePeriod }}
          submitRequest={makeEditTimeOffRequest(editablePeriod)}
          updateTimeOff={updateTimeOff}
        />
      </Modal>
    </>
  );
};

MyTimeoffManagement.propTypes = {};

MyTimeoffManagement.defaultProps = {};

export default MyTimeoffManagement;

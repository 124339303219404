import React from 'react';
import { IconButton, Modal as MaterialModal } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styles from './Modal.module.scss';
import CustomCard from '../CustomCard/CustomCard';

const Modal = ({
  open,
  onClose,
  className,
  title,
  children,
  ...extraProps
}) => {
  const cardTitle = <div>{title}</div>;
  const cardAction = (
    <IconButton onClick={onClose}>
      <CloseIcon />
    </IconButton>
  );

  return (
    <MaterialModal
      open={open}
      onClose={onClose}
      className={`${styles.modal} ${className}`}
      {...extraProps}
    >
      <div className={styles.cardContainer}>
        <CustomCard
          title={cardTitle}
          actions={cardAction}
          content={children}
          height="auto"
        />
      </div>
    </MaterialModal>
  );
};

Modal.propTypes = {};

Modal.defaultProps = {};

export default Modal;

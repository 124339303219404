import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SESSION_COOKIE_NAME,
  SESSION_COOKIE_TTL,
} from '../constants/js-constants';
import {
  updateUserProfile,
  userProfileSelector,
} from '../services/redux/slices/userProfile';
import {
  updateAccessToken,
  accessTokenSelector,
} from '../services/redux/slices/accessToken';
import { getCookie } from './misc';

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return undefined;
  }, [delay]);
}

export function useUserProfile() {
  const userProfile = useSelector(userProfileSelector);
  const dispatch = useDispatch();
  return [userProfile, (value) => dispatch(updateUserProfile(value))];
}

export function useAccessToken() {
  const accessToken = useSelector(accessTokenSelector);
  const dispatch = useDispatch();
  return [accessToken, (value) => dispatch(updateAccessToken(value))];
}

export function useSession(setCookie = null, removeCookie = null) {
  const session = getCookie(SESSION_COOKIE_NAME);

  const storeSession = () => {
    const expiryDate = new Date().getTime() + SESSION_COOKIE_TTL * 1000;
    setCookie(SESSION_COOKIE_NAME, expiryDate, {
      maxAge: SESSION_COOKIE_TTL,
    });
  };

  const deleteSession = () => {
    removeCookie(SESSION_COOKIE_NAME);
  };

  return { session, storeSession, deleteSession };
}

import { createSlice } from '@reduxjs/toolkit';

export const accessTokenSlice = createSlice({
  name: 'accessToken',
  initialState: '',
  reducers: {
    updateAccessToken: (state, action) => {
      state = action.payload;
      return state || '';
    },
    deleteAccessToken: (state) => {
      state = '';
      return state;
    },
  },
});

export const {
  updateAccessToken,
  deleteAccessToken,
} = accessTokenSlice.actions;
export const accessTokenSelector = (state) => state.accessToken;
export default accessTokenSlice.reducer;

export const DASHBOARD_PATH = '/dashboard';
export const PROFILE_PATH = '/profile';
export const USER_SETTINGS_PATH = '/profile/settings';
export const COMPANY_PATH = '/company';
export const COMPANY_EMPLOYEES_PATH = '/company/employees';
export const CREATE_COMPANY_PATH = '/company/new';
export const LOGIN_PATH = '/login';
export const LOGOUT_PATH = '/logout';
export const EMAIL_CONFIRMATION_PATH = '/confirm';
export const COMPLETE_REGISTRATION_PATH = '/complete-registration';
export const JOIN_COMPANY_PATH = '/join-company';
export const CALENDAR_PATH = '/calendar';
export const MY_TIME_OFF_PATH = '/my-time-off';
export const TRACKER_PATH = '/tracker';
export const TIME_OFF_APPROVAL_PATH =
  '/companies/:companyId/time-off/:timeOffId/approval';
export const EMPLOYEE_DETAILS_PATH =
  '/companies/:companyId/employees/:employeeId';
export const FORGOT_PASSWORD_PATH = '/forgot-password';
export const REDIRECT_AFTER_LOGIN = DASHBOARD_PATH;

export const makeEmployeeDetailsPath = (companyId, employeeId) =>
  `/companies/${companyId}/employees/${employeeId}`;
export const makeTimeOffApprovalPath = (companyId, timeOffId) =>
  `/companies/${companyId}/time-off/${timeOffId}/approval`;

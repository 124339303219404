import React, { useEffect, useState } from 'react';
import {
  fetchCompanySizeChoices,
  fetchCountryChoices,
} from '../services/api/api';

const withCreateCompanyChoices = (WrappedComponent) => (props) => {
  const [countryChoices, setCountryChoices] = useState([]);
  const [companySizeChoices, setCompanySizeChoices] = useState([]);

  useEffect(() => {
    const getCountryChoices = fetchCountryChoices();
    const getCompanySizeChoices = fetchCompanySizeChoices();
    getCountryChoices().then((response) => setCountryChoices(response));
    getCompanySizeChoices().then((response) => setCompanySizeChoices(response));
  }, []);

  return (
    <WrappedComponent
      countryChoices={countryChoices}
      companySizeChoices={companySizeChoices}
      {...props}
    />
  );
};

export default withCreateCompanyChoices;

import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import queryString from 'query-string';
import { useSession } from '../../../utils/hooks';
import { LOGIN_PATH } from '../../../routing/paths';
import styles from './ProtectedRoute.module.scss';

const ProtectedRoute = ({ children, location, ...routeProps }) => {
  const { session } = useSession();
  let currentLocation = encodeURIComponent(location.pathname + location.search);
  currentLocation = currentLocation ? `&next=${currentLocation}` : '';
  const redirectAfterLogin = `?redirect=false${currentLocation}`;
  const { token } = queryString.parse(location.search);

  if (session || token) {
    return (
      <div>
        <Route {...routeProps}>{children}</Route>
      </div>
    );
  }

  return (
    <Redirect
      to={{
        pathname: LOGIN_PATH,
        search: redirectAfterLogin,
      }}
    />
  );
};

ProtectedRoute.propTypes = {};

ProtectedRoute.defaultProps = {};

export default ProtectedRoute;

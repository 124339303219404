import Dashboard from '../components/pages/Dashboard/Dashboard';
import Profile from '../components/pages/Profile/Profile';
import Company from '../components/pages/Company/Company';
import CreateCompany from '../components/pages/CreateCompany/CreateCompany';
import CompanyEmployees from '../components/pages/CompanyEmployees/CompanyEmployees';
import CompanyCalendar from '../components/pages/CompanyCalendar/CompanyCalendar';
import MyTimeoffManagement from '../components/pages/MyTimeoffManagement/MyTimeoffManagement';
import Auth from '../components/pages/Auth/Auth';
import LoadingIndicator from '../components/common/LoadingIndicator/LoadingIndicator';
import ConfirmEmail from '../components/pages/EmailConfirmation/EmailConfirmation';
import Logout from '../components/pages/Logout/Logout';
import * as paths from './paths';
import AppInvitation from '../components/pages/AppInvitation/AppInvitation';
import TimeOffApproval from '../components/pages/TimeOffApproval/TimeOffApproval';
import EmployeeDetails from '../components/pages/CompanyEmployees/EmployeeDetails/EmployeeDetails';
import JoinCompany from '../components/pages/JoinCompany/JoinCompany';
import ForgotPassword from '../components/pages/ForgotPassword/ForgotPassword';
import Tracker from '../components/pages/Tracker/Tracker';

const HOME_ROUTE = {
  component: Dashboard,
  path: '/',
};

const DASHBOARD_ROUTE = {
  component: Dashboard,
  path: paths.DASHBOARD_PATH,
};

const PROFILE_ROUTE = {
  component: Profile,
  path: paths.PROFILE_PATH,
};

const COMPANY_ROUTE = {
  component: Company,
  path: paths.COMPANY_PATH,
};

const COMPANY_EMPLOYEES_ROUTE = {
  component: CompanyEmployees,
  path: paths.COMPANY_EMPLOYEES_PATH,
};

const CREATE_COMPANY_ROUTE = {
  component: CreateCompany,
  path: paths.CREATE_COMPANY_PATH,
};

const CALENDAR_ROUTE = {
  component: CompanyCalendar,
  path: paths.CALENDAR_PATH,
};

const MY_TIME_OFF_ROUTE = {
  component: MyTimeoffManagement,
  path: paths.MY_TIME_OFF_PATH,
};

const TIME_OFF_APPROVAL_ROUTE = {
  component: TimeOffApproval,
  path: paths.TIME_OFF_APPROVAL_PATH,
};

const EMPLOYEE_DETAILS_ROUTE = {
  component: EmployeeDetails,
  path: paths.EMPLOYEE_DETAILS_PATH,
};

const TRACKER_PATH = {
  component: Tracker,
  path: paths.TRACKER_PATH,
};

const JOIN_COMPANY_ROUTE = {
  component: JoinCompany,
  path: paths.JOIN_COMPANY_PATH,
};

const TEST_ROUTE = {
  component: LoadingIndicator,
  path: '/test',
};

const EMAIL_CONFIRMATION_ROUTE = {
  component: ConfirmEmail,
  path: paths.EMAIL_CONFIRMATION_PATH,
};

const COMPLETE_REGISTRATION_ROUTE = {
  component: AppInvitation,
  path: paths.COMPLETE_REGISTRATION_PATH,
};

const FORGOT_PASSWORD_ROUTE = {
  component: ForgotPassword,
  path: paths.FORGOT_PASSWORD_PATH,
};

const LOGIN_ROUTE = {
  component: Auth,
  path: paths.LOGIN_PATH,
};

const LOGOUT_ROUTE = {
  component: Logout,
  path: paths.LOGOUT_PATH,
};

const DEFAULT_ROUTE = {
  component: Dashboard,
  path: '*',
};

// DEFAULT_ROUTE must always be the last one in the list
export default [
  HOME_ROUTE,
  DASHBOARD_ROUTE,
  PROFILE_ROUTE,
  COMPANY_ROUTE,
  COMPANY_EMPLOYEES_ROUTE,
  CREATE_COMPANY_ROUTE,
  CALENDAR_ROUTE,
  MY_TIME_OFF_ROUTE,
  EMPLOYEE_DETAILS_ROUTE,
  TRACKER_PATH,
  LOGOUT_ROUTE,
  DEFAULT_ROUTE,
];

export const UNPROTECTED_ROUTES = [
  LOGIN_ROUTE,
  EMAIL_CONFIRMATION_ROUTE,
  COMPLETE_REGISTRATION_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  JOIN_COMPANY_ROUTE,
  TIME_OFF_APPROVAL_ROUTE,
  TEST_ROUTE,
];

import React from 'react';
import Alert from '@material-ui/lab/Alert';

const FormErrors = ({ formik, fieldName, apiErrors, fieldMapping }) => {
  const errors = [];

  if (fieldName) {
    const mappedField = fieldMapping ? fieldMapping[fieldName] : fieldName;
    if (apiErrors[mappedField]) {
      const apiError = apiErrors[mappedField];
      if (Array.isArray(apiError)) {
        errors.push(...apiError);
      } else {
        errors.push(apiError);
      }
    }
  } else if (apiErrors instanceof TypeError) {
    errors.push('There was an error connecting to the server');
  } else {
    Object.entries(apiErrors).forEach((error) => {
      if (error[0] !== 'status') {
        if (Array.isArray(error[1])) {
          errors.push(...error[1]);
        } else {
          errors.push(error[1]);
        }
      }
    });
  }

  if (formik && formik.touched[fieldName] && formik.errors[fieldName]) {
    errors.push(formik.errors[fieldName]);
  }

  if (errors.length) {
    return (
      <Alert severity="error">
        {errors.map((error) => (
          <div key={error}>{error}</div>
        ))}
      </Alert>
    );
  }
  return null;
};

export default FormErrors;

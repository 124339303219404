import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  userProfileErrorSelector,
  userProfileLoadingSelector,
  userProfileSelector,
} from '../services/redux/slices/userProfile';
import { fetchUserProfile } from '../services/api/api';
import withFetch from './withFetch';

const withUserProfile = (WrappedComponent) => ({ needsRefresh, ...props }) => {
  const history = useHistory();

  const NewComponent = withFetch(
    WrappedComponent,
    fetchUserProfile(history),
    'userProfile',
    true,
    {
      successSelector: userProfileSelector,
      errorSelector: userProfileErrorSelector,
      loadingSelector: userProfileLoadingSelector,
    },
    history
  );
  return <NewComponent props={props} />;
};

export default withUserProfile;

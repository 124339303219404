import React from 'react';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import styles from './CustomCard.module.scss';

const CustomCard = ({ title, content, height, actions }) => {
  return (
    <div className={styles.cardRoot}>
      <div className={styles.cardHeader}>
        <div className={styles.cardTitle}>{title}</div>
        <div className={styles.cardTitleActions}>{actions}</div>
      </div>
      <div className={styles.cardContent}>{content}</div>
    </div>
  );
};

export default CustomCard;

/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useDispatch } from 'react-redux';
import styles from './CompanyOverview.module.scss';
import { updateCompany as updateCompanyRedux } from '../../../../services/redux/slices/company';
import Avatar from '../../../common/Avatar/Avatar';
import { makeCompanyDetailsUrl } from '../../../../services/api/urls';

const CompanyOverview = ({ company, updateCompany }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [companyData, setCompanyData] = useState(company);
  const dispatch = useDispatch();

  const updateLogoCallback = (url) => {
    const updatedData = {
      ...companyData,
      url,
    };
    dispatch(updateCompanyRedux(updatedData));
    setCompanyData(updatedData);
  };

  const makeInputProps = (width) => {
    return {
      disabled: !company.canEdit || !isEditing,
      variant: 'outlined',
      classes: {
        root: styles.companyField,
      },
      InputProps: {
        classes: {
          disabled: styles.companyField,
          notchedOutline: styles.companyField,
          root: styles.companyField,
        },
      },
      InputLabelProps: {
        classes: {
          root: styles.companyField,
        },
      },
      style: {
        width,
      },
    };
  };

  const formik = useFormik({
    initialValues: {
      name: companyData.name,
      description: companyData.description || '-',
      address: companyData.address || '-',
      zipCode: companyData.zipCode || '-',
      countryDisplay: companyData.countryDisplay || '-',
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      description: Yup.string(),
      address: Yup.string(),
      zipCode: Yup.number(),
      countryDisplay: Yup.string().required(),
    }),
    onSubmit: (value) => {
      updateCompany(value).then((data) => dispatch(updateCompanyRedux(data)));
    },
  });

  const handleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleSubmit = (event) => {
    if (!isEditing) {
      formik.handleSubmit(event);
    }
    event.preventDefault();
  };

  return (
    <div>
      <div className={styles.companySummary}>
        <div className={styles.banner}>
          <div className={styles.avatar}>
            <Avatar
              width="100px"
              height="100px"
              name={companyData.name[0]}
              url={companyData.logo}
              fileUploadUrl={
                companyData.canEdit
                  ? makeCompanyDetailsUrl(companyData.id)
                  : null
              }
              callback={updateLogoCallback}
              fieldName="logo"
            />
          </div>
        </div>
        <div className={styles.companyData}>
          <form onSubmit={handleSubmit}>
            <div>
              <TextField
                label="Name"
                id="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                {...makeInputProps('100%')}
              />
            </div>
            <div>
              <TextField
                label="Description"
                id="description"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                {...makeInputProps('100%')}
              />
            </div>
            <div>
              <TextField
                label="Address"
                id="address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address}
                {...makeInputProps('100%')}
              />
            </div>
            <div className={styles.multiFieldLine}>
              <TextField
                label="Zip Code"
                id="zipCode"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.zipCode}
                {...makeInputProps('45%')}
              />
              <TextField
                label="Country"
                id="countryDisplay"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.countryDisplay}
                {...makeInputProps('45%')}
              />
            </div>
            <div className={styles.buttonContainer}>
              {companyData.canEdit && (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleEdit}
                >
                  {isEditing ? 'Save' : 'Edit'}
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CompanyOverview;

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { CookiesProvider } from 'react-cookie';
import App from './components/App/App';
import store, { persistor } from './services/redux/store';

/*
Add logic to enable production deploy
in production all warnings should be surpressed
*/
if (process.env.NODE_ENV==='production') {
  console.log = console.warn = console.error = () => {};
}

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

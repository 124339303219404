import React from 'react';
import styles from './TimeOffDetails.module.scss';

const TimeOffDetails = ({ timeOff }) => {
  return (
    <div className={styles.container}>
      Employee: {timeOff.userFullName} <br />
      Type: {timeOff.typeDisplay} <br />
      Starts at: {timeOff.startsAt} <br />
      Ends at: {timeOff.endsAt} <br />
      Details: {timeOff.description} <br />
    </div>
  );
};

export default TimeOffDetails;

import React, { useEffect, useState } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
  MenuItem,
  Select,
} from '@material-ui/core';
import {
  Menu,
  Dashboard,
  PermIdentity,
  Business,
  PeopleOutline,
  CalendarToday,
  NaturePeople,
} from '@material-ui/icons';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import classNames from 'classnames';
import styles from './Sidebar.module.scss';
import {
  CALENDAR_PATH,
  COMPANY_EMPLOYEES_PATH,
  COMPANY_PATH,
  DASHBOARD_PATH,
  MY_TIME_OFF_PATH,
  PROFILE_PATH,
  TRACKER_PATH,
} from '../../../routing/paths';
import UserBadge from '../UserBadge/UserBadge';
import { userProfileSelector } from '../../../services/redux/slices/userProfile';
import { fetchUserProfile } from '../../../services/api/api';
import { checkObjectIsEmpty } from '../../../utils/misc';

const myItems = [
  { path: DASHBOARD_PATH, icon: Dashboard, text: 'Dashboard' },
  { path: PROFILE_PATH, icon: PermIdentity, text: 'Profile' },
  { path: MY_TIME_OFF_PATH, icon: NaturePeople, text: 'Time off' },
  { path: TRACKER_PATH, icon: AccessTimeIcon, text: 'Tracker' },
];

const companyItems = [
  { path: COMPANY_PATH, icon: Business, text: 'Company' },
  { path: COMPANY_EMPLOYEES_PATH, icon: PeopleOutline, text: 'Employees' },
  {
    path: CALENDAR_PATH,
    icon: CalendarToday,
    text: 'Calendar',
  },
];

const Sidebar = ({ window }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userProfile = useSelector(userProfileSelector);
  const userCompanies = userProfile.companies || [];
  const userCompaniesMap = userCompanies.length
    ? userProfile.companies.reduce((acc, company) => {
        acc[company.id] = company;
        return acc;
      }, {})
    : {};

  useEffect(() => {
    if (checkObjectIsEmpty(userProfile)) {
      const makeFetch = fetchUserProfile(history);
      dispatch(makeFetch());
    }
  }, []);

  const renderCompany = (company) =>
    userCompanies && userCompanies.length
      ? userCompaniesMap[company].name
      : userCompanies[0].name;
  if (!userProfile || !Object.keys(userProfile).length) {
    return <></>;
  }

  const companies =
    userCompanies && userCompanies.length
      ? userCompanies.map((company) => (
          <MenuItem value={company.id} key={company.id}>
            {company.name}
          </MenuItem>
        ))
      : [];

  const renderItems = (items) => {
    return items.map((item) => (
      <NavLink
        to={item.path}
        activeClassName={styles.linkActive}
        className={styles.sidebarItem}
        exact
        key={item.text}
      >
        <div className={styles.sidebarIcon}>
          <item.icon />
          {item.text}
        </div>
        <ChevronRightIcon className={styles.arrowIcon} />
      </NavLink>
    ));
  };

  return (
    <div className={styles.root}>
      <div className={styles.tabSeparator}>YOU</div>
      {renderItems(myItems)}
      <div className={styles.sidebarSeparator}>
        {userCompanies.length && userCompanies[0].id ? (
          <Select
            id="selectedCompany"
            value={userCompanies[0].id}
            // onChange={updateUserCompany}
            renderValue={renderCompany}
          >
            {companies}
          </Select>
        ) : (
          <>My Company</>
        )}
      </div>
      {renderItems(companyItems)}
      <div className={styles.userBadgeContainer}>
        <UserBadge userProfile={userProfile} />
      </div>
    </div>
  );

  // if (userCompanies.length && !selectedCompany) {
  //   dispatch(updateSelectedCompany(userCompanies[0]));
  // }

  // const updateUserCompany = (company) => {
  //   dispatch(updateSelectedCompany(company));
  // };

  // const renderCompany = (company) =>
  //   companies && companies.length
  //     ? userCompaniesMap[company].name
  //     : selectedCompany.name;
  // if (!userProfile || !Object.keys(userProfile).length) {
  //   return <></>;
  // }

  // const drawer = (
  //   <div className={styles.drawerContainer}>
  //     <List className={styles.itemsList}>
  //       <div className={styles.sidebarSeparator}>My RaptorHR</div>
  //       {renderItems(myItems)}
  //       <div className={styles.sidebarSeparator}>
  //         {selectedCompany && selectedCompany.id ? (
  //           <Select
  //             id="selectedCompany"
  //             value={selectedCompany.id}
  //             onChange={updateUserCompany}
  //             renderValue={renderCompany}
  //           >
  //             {companies}
  //           </Select>
  //         ) : (
  //           <>My Company</>
  //         )}
  //       </div>
  //       {renderItems(companyItems)}
  //       <div className={styles.userBadgeContainer}>
  //         <UserBadge userProfile={userProfile} />
  //       </div>
  //     </List>
  //   </div>
  // );

  // return (
  //   <div className={styles.root}>
  //     <Hidden mdUp>
  //       <AppBar className={styles.appBar} position="fixed">
  //         <Toolbar>
  //           <IconButton
  //             color="inherit"
  //             edge="start"
  //             onClick={handleDrawerToggle}
  //           >
  //             <Menu />
  //           </IconButton>
  //         </Toolbar>
  //       </AppBar>
  //     </Hidden>
  //     <nav className={styles.drawer}>
  //       <Hidden mdUp>
  //         <Drawer
  //           variant="temporary"
  //           container={container}
  //           open={open}
  //           onClose={handleDrawerToggle}
  //           ModalProps={{
  //             keepMounted: true,
  //           }}
  //           classes={{
  //             paper: styles.drawerPaper,
  //           }}
  //         >
  //           {drawer}
  //         </Drawer>
  //       </Hidden>
  //       <Hidden smDown>
  //         <Drawer variant="permanent" classes={{ paper: styles.drawerPaper }}>
  //           {drawer}
  //         </Drawer>
  //       </Hidden>
  //     </nav>
  //   </div>
  // );
};

Sidebar.propTypes = {};

Sidebar.defaultProps = {};

export default Sidebar;

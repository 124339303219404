/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import styles from './CompanyTimeOff.module.scss';

const CompanyTimeOff = ({ company, updateCompany }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [timeOffDaysTotal, setTimeOffDaysTotal] = useState(
    company.timeOffDaysTotal || 0
  );
  const [holidayNeedsApproval, setHolidayNeedsApproval] = useState(
    company.holidayNeedsApproval
  );
  const [sickLeaveNeedsApproval, setSickLeaveNeedsApproval] = useState(
    company.sickLeaveNeedsApproval
  );
  const [
    maternityLeaveNeedsApproval,
    setMaternityLeaveNeedsApproval,
  ] = useState(company.maternityLeaveNeedsApproval);
  const [
    specialLeaveNeedsApproval,
    setSpecialLeaveNeedsApproval,
  ] = useState(company.specialLeaveNeedsApproval);

  const makeInputProps = (width) => {
    return {
      disabled: !company.canEdit || !isEditing,
      variant: 'outlined',
      classes: {
        root: styles.companyField,
      },
      InputProps: {
        classes: {
          disabled: styles.companyField,
          notchedOutline: styles.companyField,
          root: styles.companyField,
        },
      },
      InputLabelProps: {
        classes: {
          root: styles.companyField,
        },
      },
      style: {
        width,
      },
    };
  };

  const handleEdit = () => {
    if (isEditing) {
      updateCompany({
        timeOffDaysTotal,
        sickLeaveNeedsApproval,
        holidayNeedsApproval,
        maternityLeaveNeedsApproval,
      });
    }
    setIsEditing(!isEditing);
  };

  const editTimeOffDaysTotal = (event) => {
    setTimeOffDaysTotal(event.target.value);
  };

  const handleHolidayNeedsApproval = (event) => {
    setHolidayNeedsApproval(event.target.checked);
  };

  const handleSickLeaveNeedsApproval = (event) => {
    setSickLeaveNeedsApproval(event.target.checked);
  };

  const handleMaternityLeaveNeedsApproval = (event) => {
    setMaternityLeaveNeedsApproval(event.target.checked);
  };

  const handleSpecialLeaveNeedsApproval = (event) => {
    setSpecialLeaveNeedsApproval(event.target.checked);
  };

  return (
    <div>
      <div className={styles.companyData}>
        <>
          <div className={styles.input}>
            <TextField
              label="Time off days"
              defaultValue={company.timeOffDaysTotal}
              onChange={editTimeOffDaysTotal}
              checked={timeOffDaysTotal}
              {...makeInputProps('100%')}
            />
          </div>
          <div className={styles.checkBoxContainer}>
            <h3>Holidays types that require approval:</h3>
            <FormControlLabel
              control={
                <Checkbox
                  name="holiday"
                  color="primary"
                  checked={holidayNeedsApproval}
                  disabled={!company.canEdit || !isEditing}
                  onClick={handleHolidayNeedsApproval}
                />
              }
              label="Holiday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="sickLeave"
                  color="primary"
                  checked={sickLeaveNeedsApproval}
                  disabled={!company.canEdit || !isEditing}
                  onClick={handleSickLeaveNeedsApproval}
                />
              }
              label="Sick leave"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="maternityLeave"
                  color="primary"
                  checked={maternityLeaveNeedsApproval}
                  disabled={!company.canEdit || !isEditing}
                  onClick={handleMaternityLeaveNeedsApproval}
                />
              }
              label="Maternity leave"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="specialLeave"
                  color="primary"
                  checked={specialLeaveNeedsApproval}
                  disabled={!company.canEdit || !isEditing}
                  onClick={handleSpecialLeaveNeedsApproval}
                />
              }
              label="Special leave"
            />
          </div>
          <div className={styles.buttonContainer}>
            {company.canEdit && (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleEdit}
              >
                {isEditing ? 'Save' : 'Edit'}
              </Button>
            )}
          </div>
        </>
      </div>
    </div>
  );
};

export default CompanyTimeOff;

import React from 'react';
import { timeOffTypeChoicesSelector } from '../services/redux/slices/choices';
import { fetchTimeOffTypeChoices } from '../services/api/api';
import withFetch from './withFetch';

const withTimeOffTypeChoices = (WrappedComponent) => (props) => {
  const NewComponent = withFetch(
    WrappedComponent,
    fetchTimeOffTypeChoices,
    'timeOffTypeChoices',
    true,
    {
      successSelector: timeOffTypeChoicesSelector,
    }
  );
  return <NewComponent {...props} />;
};

export default withTimeOffTypeChoices;

import { Button } from '@material-ui/core';
import React from 'react';
import CustomCard from '../../common/CustomCard/CustomCard';
import TrackerTimer from './TrackerTimer/TrackerTimer';

const Tracker = ({ user }) => {
  return <CustomCard title="Tracker" content={<TrackerTimer user={user} />} />;
};

export default Tracker;

import React from 'react';
import { useSelector } from 'react-redux';
import { makeTimeOffDetailsUrl } from '../../../../services/api/urls';
import { userProfileSelector } from '../../../../services/redux/slices/userProfile';
import AdditionalFiles from '../../../common/AdditionalFiles/AdditionalFiles';
import Modal from '../../../common/Modal/Modal';
import styles from './TimeOffDetailsModal.module.scss';

const TimeOffDetailsModal = ({ timeOff, open, onClose }) => {
  const userProfile = useSelector(userProfileSelector);

  let status;
  if (timeOff.isApproved) {
    status = 'Approved';
  } else if (timeOff.isRejected) {
    status = 'Rejected';
  } else {
    status = 'Not approved yet';
  }

  return (
    <Modal title={timeOff.name} open={open} onClose={onClose}>
      <div className={styles.field}>
        <b className={styles.fieldName}>Starts at: </b>
        {timeOff.startsAt}
      </div>
      <div className={styles.field}>
        <b className={styles.fieldName}>Ends at: </b>
        {timeOff.endsAt}
      </div>
      {timeOff.eventType === 'time_off' && (
        <>
          <div className={styles.field}>
            <b className={styles.fieldName}>Needs approval: </b>
            {timeOff.needsApproval ? 'Yes' : 'No'}
          </div>
          {timeOff.status && (
            <div className={styles.field}>
              <b className={styles.fieldName}>Status: </b>
              {status}
            </div>
          )}
        </>
      )}
      <div className={styles.field}>
        <b className={styles.fieldName}>Details: </b> {timeOff.description || ''}
      </div>
      <div className={`${styles.field} ${styles.attachments}`}>
        <b className={styles.fieldName} style={{ marginRight: '5px' }}>
          Attachments:
        </b>
        {timeOff.additionalFiles ? (
          <AdditionalFiles
            files={[timeOff.additionalFiles]}
            fileUploadUrl={makeTimeOffDetailsUrl(
              userProfile.companies[0].id,
              timeOff.id
            )}
            fieldName="additional_files"
          />
        ) : (
          '-'
        )}
      </div>
    </Modal>
  );
};

export default TimeOffDetailsModal;

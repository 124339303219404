import React, { useState } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import styles from './Company.module.scss';
import CustomCard from '../../common/CustomCard/CustomCard';
import withCompany from '../../../containers/withCompany';
import CompanyTimeOff from './CompanyTimeOff/CompanyTimeOff';
import CompanyOverview from './CompanyOverview/CompanyOverview';
import CompanyEvents from './CompanyEvents/CompanyEvents';
import { patchCompany } from '../../../services/api/api';

const Company = ({ company }) => {
  const history = useHistory();
  const updateCompany = patchCompany(company.id, history);

  const overviewTab = (
    <CompanyOverview company={company} updateCompany={updateCompany} />
  );
  const eventsTab = (
    <CompanyEvents company={company} updateCompany={updateCompany} />
  );
  const timeOffTab = (
    <CompanyTimeOff company={company} updateCompany={updateCompany} />
  );

  const [selectedTab, setSelectedTab] = useState('1');
  const [tabContent, setTabContent] = useState(overviewTab);

  const componentValueMapping = {
    1: overviewTab,
    2: timeOffTab,
    3: eventsTab,
  };

  const handleTabSelect = (event, newValue) => {
    setSelectedTab(newValue);
    setTabContent(componentValueMapping[newValue]);
  };

  const content = (
    <div className={styles.tabsContainer}>
      <Tabs
        onChange={handleTabSelect}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        value={selectedTab}
        className={styles.tabSelector}
      >
        <Tab classes={{ root: styles.tab }} label="Overview" value="1" />
        <Tab classes={{ root: styles.tab }} label="Time Off" value="2" />
        <Tab classes={{ root: styles.tab }} label="Events" value="3" />
      </Tabs>
      <div className={styles.tabContentContainer}>{tabContent}</div>
    </div>
  );
  return <CustomCard content={content} />;
};

export default withCompany(Company);

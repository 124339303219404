import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LoadingIndicator from '../components/common/LoadingIndicator/LoadingIndicator';
import NoCompanyFound from '../components/pages/NoCompanyFound/NoCompanyFound';
import {
  companyErrorSelector,
  companyLoadingSelector,
  companySelector,
  selectedCompanySelector,
  updateSelectedCompany,
} from '../services/redux/slices/company';
import { userProfileSelector } from '../services/redux/slices/userProfile';
import { fetchCompany, fetchUserProfile } from '../services/api/api';
import { checkObjectIsEmpty } from '../utils/misc';
import withFetch from './withFetch';

const withCompany = (WrappedComponent) => ({ ...props }) => {
  const userProfile = useSelector(userProfileSelector);
  let selectedCompany = useSelector(selectedCompanySelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const userProfileIsEmpty = checkObjectIsEmpty(userProfile);

  useEffect(() => {
    if (userProfileIsEmpty) {
      dispatch(fetchUserProfile(history));
    }
    return () => {};
  }, []);

  if (userProfileIsEmpty) {
    return <LoadingIndicator />;
  }

  if (!userProfile.companies.length) {
    return <NoCompanyFound />;
  }

  if (!selectedCompany) {
    dispatch(updateSelectedCompany(userProfile.companies[0]));
    [selectedCompany] = userProfile.companies;
  }

  const NewComponent = withFetch(
    WrappedComponent,
    fetchCompany(selectedCompany.id, history),
    'company',
    true,
    {
      successSelector: companySelector,
      errorSelector: companyErrorSelector,
      loadingSelector: companyLoadingSelector,
    }
  );
  return <NewComponent props={props} />;
};

export default withCompany;

import { createSlice } from '@reduxjs/toolkit';

export const companySlice = createSlice({
  name: 'company',
  initialState: null,
  reducers: {
    updateCompany: (state, action) => {
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload,
      };
    },
    updateSelectedCompany: (state, action) => {
      return {
        ...state,
        selectedCompany: action.payload,
      };
    },
    fetchCompanyBegin: (state) => {
      return { ...state, loading: true, data: null, error: null };
    },
    fetchCompanyFailure: (state, action) => {
      return { ...state, loading: false, error: action.payload, data: null };
    },
  },
});

export const {
  updateCompany,
  fetchCompanyBegin,
  fetchCompanyFailure,
  updateSelectedCompany,
} = companySlice.actions;

export const companySelector = (state) =>
  state.company ? state.company.data : {};
export const companyErrorSelector = (state) =>
  state.company ? state.company.error : {};
export const companyLoadingSelector = (state) =>
  state.company ? state.company.loading : {};
export const selectedCompanySelector = (state) =>
  state.company ? state.company.selectedCompany : null;

export default companySlice.reducer;

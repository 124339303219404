/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { TextField } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { DATE_FORMAT } from '../../../constants/js-constants';
import styles from './FormInput.module.scss';

const FormInput = ({
  type,
  formikObject,
  fieldName,
  label,
  editable,
  handleChange,
  variant,
  textFieldType,
  fullLength,
  autoComplete,
  ...extraProps
}) => {
  let element;

  const props = {
    id: fieldName,
    disabled: editable === false,
    value: formikObject.values[fieldName],
    onChange: handleChange || formikObject.handleChange,
    onBlur: formikObject.handleBlur,
    variant: 'outlined',
    margin: 'normal',
    classes: fullLength ? { root: styles.fullLengthField } : {},
    label,
    autoComplete,
    ...extraProps,
  };

  if (type === 'text') {
    element = <TextField type={textFieldType || 'text'} {...props} />;
  } else if (type === 'date') {
    element = (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          format={DATE_FORMAT}
          variant="inline"
          allowKeyboardControl
          autoOk
          {...props}
        />
      </MuiPickersUtilsProvider>
    );
  } else if (type === 'select') {
    element = (
      <TextField
        select
        onChange={formikObject.handleChange(props.id)}
        {...props}
      >
        {props.choices}
      </TextField>
    );
  }

  return element;
};

export default FormInput;

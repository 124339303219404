import React, { useState } from 'react';
import { useFormik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { MOMENT_DATE_FORMAT } from '../../../constants/js-constants';
import { BASE_API_URL, MY_PROFILE_URL } from '../../../services/api/urls';
import FormInput from '../FormInput/FormInput';
import Avatar from '../Avatar/Avatar';
import styles from './UserEditForm.module.scss';
import DigitalSignature from '../DigitalSignature/DigitalSignature';

const UserEditForm = ({ userProfile, saveCallback, is_manager ,showUserThroughFields }) => {
  const [profileData, setProfileData] = useState(userProfile);
  const [hasChanges, setHasChanges] = useState(false);

  const initialBirthDate = userProfile.birthDate
    ? moment(profileData.birthDate, MOMENT_DATE_FORMAT).toDate()
    : null;

  const formik = useFormik({
    initialValues: {
      fullName: profileData.fullName,
      address: profileData.address || '-',
      birthDate: initialBirthDate,
      hideBirthDate: profileData.hideBirthDate,
      phoneNumber: profileData.phoneNumber || '-',
      jobTitle: profileData.jobTitle || '-',
      useSignature: profileData.useSignature || false,
      generateWord: profileData.generateWord || false,
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required(),
      address: Yup.string().nullable(),
      hideBirthDate: Yup.bool().nullable(),
      phoneNumber: Yup.string().nullable(),
      jobTitle: Yup.string().nullable(),
      useSignature: Yup.bool().nullable(),
      generateWord: Yup.bool().nullable(),
    }),
    onSubmit: (value) => {
      setHasChanges(false);
      const dataToSend = {
        ...value,
        birthDate: moment(value.birthDate).format(MOMENT_DATE_FORMAT),
        phoneNumber: value.phoneNumber || null,
      };
      if (saveCallback) {
        saveCallback(dataToSend);
      }
      setProfileData(dataToSend);
    },
  });

  const handleBirthDateChange = (option) => {
    formik.setFieldValue('birthDate', option);
    setHasChanges(true);
  };

  const handleChange = (option) => {
    setHasChanges(true);
    formik.handleChange(option);
  };

  const handleAvatarUpdate = (url) => {
    profileData.avatar = url;
    setProfileData(profileData);
    saveCallback(profileData);
  };

  return (
    <>
      <div className={styles.profileData}>
        <Avatar
          name={profileData.fullName}
          width="110px"
          height="110px"
          initials
          url={profileData.avatar}
          fileUploadUrl={`${BASE_API_URL}${MY_PROFILE_URL}`}
          fieldName="avatar"
          callback={handleAvatarUpdate}
        />
        <form onSubmit={formik.handleSubmit}>
          <div className={styles.profileField}>
            <FormInput
              type="text"
              fieldName="fullName"
              label="Name"
              autoComplete="name"
              formikObject={formik}
              handleChange={handleChange}
              fullLength
            />
          </div>
          <div className={styles.profileField}>
            <FormInput
              type="text"
              fieldName="jobTitle"
              label="Job title"
              autoComplete="organization-title"
              formikObject={formik}
              fullLength
              handleChange={handleChange}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.useSignature}
                  onChange={handleChange}
                  name="useSignature"
                  color="primary"
                />
              }
              label="Use signature to automatically sign leave requests"
            />
            {is_manager==true && (<FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.generateWord}
                  onChange={handleChange}
                  name="generateWord"
                  color="primary"
                />
              }
              label="Generate word document for leave request"
            /> )}
          </div>
          <div className={styles.profileField}>
            <FormInput
              type="text"
              fieldName="address"
              label="Address"
              autoComplete="street-address"
              formikObject={formik}
              fullLength
              handleChange={handleChange}
            />
          </div>
          <div className={styles.profileField}>
            <FormInput
              type="date"
              fieldName="birthDate"
              label="Birth Date"
              formikObject={formik}
              autoComplete="bday"
              inputVariant="outlined"
              handleChange={handleBirthDateChange}
              fullWidth
              disableFuture
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.hideBirthDate}
                  onChange={handleChange}
                  name="hideBirthDate"
                  color="primary"
                />
              }
              label="Hide your birth date from the dashboard"
            />
          </div>
          <div className={styles.profileField}>
            <FormInput
              type="text"
              fieldName="phoneNumber"
              label="Phone Number"
              autoComplete="tel"
              formikObject={formik}
              fullLength
              handleChange={handleChange}
            />
          </div>
          <div className={styles.buttonContainer}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!hasChanges}
            >
              Save
            </Button>
          </div>
        </form>
        <div>
          {is_manager==true ? <div/> : <DigitalSignature/>}
        </div>
      </div>
    </>
  );
};

export default UserEditForm;

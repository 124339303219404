import React, { useState } from 'react';
import queryString from 'query-string';
import { Redirect, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Button, FormGroup, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import styles from './ForgotPassword.module.scss';
import { DASHBOARD_PATH, LOGIN_PATH } from '../../../routing/paths';
import CustomCard from '../../common/CustomCard/CustomCard';
import { useSession } from '../../../utils/hooks';
import FormErrors from '../../common/FormErrors/FormErrors';
import { completeRegistration } from '../../../services/api/api';
import { REQUIRED_FIELD_ERROR } from '../../../constants/js-constants';
import { PASSWORD_RESET_URL } from '../../../services/api/urls';

export const ForgotPassword = () => {
  const history = useHistory();
  const { session } = useSession();
  const { token } = queryString.parse(history.location.search);
  const [apiErrors, setApiErrors] = useState({});
  const [completed, setCompleted] = useState(false);
  const makeRequest = completeRegistration(PASSWORD_RESET_URL);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required(REQUIRED_FIELD_ERROR),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords do not match')
        .required(),
    }),
    onSubmit: (value) => {
      makeRequest({ ...value, token })
        .then(() => {
          setCompleted(true);
        })
        .catch((errors) => setApiErrors(errors));
    },
  });

  if (session || !token) {
    return <Redirect to={DASHBOARD_PATH} />;
  }

  let cardTitle;
  let cardContent;

  if (completed) {
    const redirectToLogin = () => history.replace(LOGIN_PATH);
    cardTitle = 'Registration completed';
    cardContent = (
      <>
        <p>Your password has been reset</p>
        <p>Click the button below to log in.</p>
        <div className={styles.buttonContainer}>
          <Button onClick={redirectToLogin} variant="contained" color="primary">
            Go to login
          </Button>
        </div>
      </>
    );
  } else {
    cardTitle = 'Reset your password';
    cardContent = (
      <form onSubmit={formik.handleSubmit}>
        <FormGroup>
          <div>
            <TextField
              id="password"
              label="Password"
              type="password"
              variant="outlined"
              margin="normal"
              autoComplete="new-password"
              fullWidth
              clasess={{ root: styles.fullLengthField }}
              onChange={formik.handleChange}
              value={formik.values.password}
              onBlur={formik.onBlur}
            />
            <FormErrors
              formik={formik}
              fieldName="password"
              apiErrors={apiErrors}
            />
          </div>
        </FormGroup>
        <FormGroup>
          <div>
            <TextField
              id="confirmPassword"
              label="Confirm password"
              type="password"
              variant="outlined"
              margin="normal"
              autoComplete="new-password"
              fullWidth
              clasess={{ root: styles.fullLengthField }}
              onChange={formik.handleChange}
              value={formik.values.confirmPassword}
              onBlur={formik.onBlur}
            />
            <FormErrors
              formik={formik}
              fieldName="confirmPassword"
              apiErrors={apiErrors}
            />
          </div>
        </FormGroup>
        <FormErrors formik={formik} apiErrors={apiErrors} />
        <div className={styles.buttonContainer}>
          <Button type="submit" variant="contained" color="primary">
            Confirm
          </Button>
        </div>
      </form>
    );
  }

  return (
    <div className={styles.container}>
      <CustomCard title={cardTitle} content={cardContent} height="auto" />
    </div>
  );
};

export default ForgotPassword;

import React, { useState } from 'react';
import moment from 'moment';
import { Button } from '@material-ui/core';
import styles from './TimeOffListItem.module.scss';
import { MOMENT_DATE_FORMAT } from '../../../constants/js-constants';
import TimeOffDetailsModal from '../../pages/CompanyCalendar/TimeOffDetailsModal/TimeOffDetailsModal';

const TimeOffListItem = ({
  timeOff,
  extraActionName,
  extraActionHandler,
  hideDuration,
  unclickable,
}) => {
  const [selectedTimeOff, setSelectedTimeOff] = useState(null);

  const handleModalClose = () => {
    setSelectedTimeOff(null);
  };

  const convertDate = (date) => {
    if (!(date instanceof Date)) {
      return moment(date, MOMENT_DATE_FORMAT, true);
    }
    return date;
  };

  const startsAt = convertDate(timeOff.startsAt);
  const endsAt = convertDate(timeOff.endsAt);

  const calendar = (
    <>
      {selectedTimeOff && (
        <TimeOffDetailsModal
          timeOff={{ name: selectedTimeOff.typeD, ...selectedTimeOff }}
          open
          onClose={handleModalClose}
        />
      )}
      <div
        className={styles.container}
        onClick={() => unclickable || setSelectedTimeOff(timeOff)}
        style={{ cursor: unclickable ? 'default' : 'pointer' }}
      >
        <div className={styles.calendarItem}>
          <div className={styles.month}>{startsAt.format('MMM')}</div>
          <div className={styles.day}>{startsAt.format('D')}</div>
        </div>
        {timeOff.endsAt !== timeOff.startsAt && (
          <>
            <div className={styles.to}>to</div>
            <div className={styles.calendarItem}>
              <div className={styles.month}>{endsAt.format('MMM')}</div>
              <div className={styles.day}>{endsAt.format('D')}</div>
            </div>
          </>
        )}
        <div className={styles.timeOffInfo}>
          <div className={styles.timeOffType}>
            {timeOff.userFullName ? timeOff.userFullName : ''}
            {timeOff.typeDisplay
              ? `${timeOff.userFullName ? ' - ' : ''}${timeOff.typeDisplay}`
              : ''}
          </div>
          {!hideDuration && (
            <div className={styles.timeOffDuration}>{`${
              timeOff.duration || 1
            } day${timeOff.duration > 1 ? 's' : ''}`}</div>
          )}
        </div>
        {extraActionName && (
          <div className={styles.extraActionButton}>
            <Button
              variant="contained"
              color="primary"
              onClick={extraActionHandler}
            >
              {extraActionName}
            </Button>
          </div>
        )}
      </div>
    </>
  );

  return calendar;
};

export default TimeOffListItem;

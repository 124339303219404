import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  FormGroup,
  InputLabel,
  MenuItem,
  TextField,
  Button,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { format } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import styles from './EditTimeoffForm.module.scss';
import withTimeOffTypeChoices from '../../../../containers/withTimeOffTypeChoices';
import {
  DATE_FORMAT,
  MOMENT_DATE_FORMAT,
} from '../../../../constants/js-constants';
import FormErrors from '../../../common/FormErrors/FormErrors';
import { timeOffFieldMapping } from '../../../../utils/parsers';
import moment from 'moment';

const EditTimeoffForm = ({
  closeModal,
  timeOffTypeChoices,
  initialValues,
  submitRequest,
  updateTimeOff,
}) => {
  const [fetchError, setFetchError] = useState({});
  const today = new Date();

  ['startsAt', 'endsAt'].forEach((value) => {
    if (initialValues[value]) {
      initialValues[value] = moment(initialValues[value], MOMENT_DATE_FORMAT);
    }
  });
  const formik = useFormik({
    initialValues: {
      startsAt: initialValues.startsAt || today,
      endsAt: initialValues.endsAt || today,
      type: initialValues.type || '',
      description: initialValues.description || '',
    },
    validationSchema: Yup.object({
      startsAt: Yup.string().required(),
      endsAt: Yup.date(),
      type: Yup.string().required(),
      description: Yup.string(),
    }),
    onSubmit: (value) => {
      let startsAt;
      let endsAt;
      try {
        startsAt = format(value.startsAt, DATE_FORMAT);
      } catch {
        startsAt = value.startsAt.format(MOMENT_DATE_FORMAT);
      }
      try {
        endsAt = format(value.endsAt, DATE_FORMAT);
      } catch {
        endsAt = value.endsAt.format(MOMENT_DATE_FORMAT);
      }
      const formattedValue = {
        ...initialValues,
        ...value,
        startsAt,
        endsAt,
      };
      submitRequest(formattedValue)
        .then((response) => {
          updateTimeOff(response);
          closeModal();
        })
        .catch((error) => {
          setFetchError(error);
        });
    },
  });

  const handleStartsAtChange = (option) => {
    formik.setFieldValue('startsAt', option);
    if (!('endsAt' in formik.touched)) {
      formik.setFieldValue('endsAt', option);
    }
  };

  const handleFileUpload = () => {};

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormGroup row className={styles.formRow}>
        <InputLabel className={styles.inputLabel}>Type</InputLabel>
        <TextField
          select
          id="type"
          required
          variant="outlined"
          classes={{ root: styles.dropdownField }}
          onChange={formik.handleChange('type')}
          value={formik.values.type}
          className={styles.fullLengthField}
        >
          {timeOffTypeChoices.map((choice) => (
            <MenuItem value={choice.choice_code} key={choice.choice_code}>
              {choice.choice_label}
            </MenuItem>
          ))}
        </TextField>
      </FormGroup>
      <div className={styles.inlineForm}>
        <FormGroup row className={styles.inlineFormInput}>
          <InputLabel className={styles.inputLabel}>Start Date</InputLabel>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              id="startsAt"
              format={DATE_FORMAT}
              variant="inline"
              value={formik.values.startsAt}
              onChange={handleStartsAtChange}
              onBlur={formik.handleBlur}
            />
          </MuiPickersUtilsProvider>
          <FormErrors
            formik={formik}
            fieldName="startsAt"
            apiErrors={fetchError}
            fieldMapping={timeOffFieldMapping}
          />
        </FormGroup>
        <FormGroup row className={styles.inlineFormInput}>
          <InputLabel className={styles.inputLabel}>End Date</InputLabel>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              id="endsAt"
              format={DATE_FORMAT}
              variant="inline"
              value={formik.values.endsAt}
              onChange={(option) => formik.setFieldValue('endsAt', option)}
              onBlur={formik.handleBlur}
              minDate={formik.values.startsAt}
            />
          </MuiPickersUtilsProvider>
          <FormErrors
            formik={formik}
            fieldName="endsAt"
            apiErrors={fetchError}
            fieldMapping={timeOffFieldMapping}
          />
        </FormGroup>
      </div>
      {/* <FormGroup
        row
        className={styles.formRow}
        style={{ flexDirection: 'column' }}
      >
        <InputLabel className={styles.inputLabel}>Additional files</InputLabel>
        <input
          id="fileUpload"
          type="file"
          className={styles.originalInput}
          onChange={handleFileUpload}
        />
        <FormErrors
          formik={formik}
          fieldName="endsAt"
          apiErrors={fetchError}
          fieldMapping={timeOffFieldMapping}
        />
      </FormGroup> */}
      <FormGroup row className={styles.formRow}>
        <InputLabel className={styles.inputLabel}>Details</InputLabel>
        <TextField
          id="description"
          variant="outlined" 
          value={formik.values.description}
          onChange={formik.handleChange('description')}
          className={styles.fullLengthField}
        >
      </TextField>
      </FormGroup>
      <FormErrors
        formik={formik}
        apiErrors={fetchError}
        fieldMapping={timeOffFieldMapping}
      />
      <div className={styles.buttonContainer}>
        <Button type="submit" variant="contained" color="primary">
          Edit Time Off
        </Button>
      </div>
    </form>
  );
};

EditTimeoffForm.propTypes = {};

EditTimeoffForm.defaultProps = {};

export default withTimeOffTypeChoices(EditTimeoffForm);

import React from 'react';
import styles from './ErrorPage.module.scss';

const ErrorPage = () => (
  <div className={styles.ErrorPage}>ErrorPage Component</div>
);

ErrorPage.propTypes = {};

ErrorPage.defaultProps = {};

export default ErrorPage;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { Link, TextField } from '@material-ui/core';
import styles from './Auth.module.scss';
import { useSession } from '../../../utils/hooks';
import { signUp } from '../../../services/api/api';
import { LOGOUT_PATH, REDIRECT_AFTER_LOGIN } from '../../../routing/paths';
import LoginForm from './LoginForm/LoginForm';
import CustomCard from '../../common/CustomCard/CustomCard';
import SignUpForm from './SignUpForm/SignUpForm';
import Modal from '../../common/Modal/Modal';
import ForgotPasswordForm from './ForgotPasswordForm/ForgotPasswordForm';

const Auth = ({ location }) => {
  const [signUpModalOpen, toggleSignUpModalOpen] = useState(false);
  const [registrationFinished, setRegistrationFinished] = useState(false);
  const [forgotPasswordModalOpen, toggleForgotPasswordModalOpen] = useState(
    false
  );
  const history = useHistory();
  const [, setCookie] = useCookies(['no-cookie-can-rerender-me']);
  const { next, redirect } = queryString.parse(location.search);
  const { session, storeSession } = useSession(setCookie);
  const goNext = !next || next === LOGOUT_PATH ? REDIRECT_AFTER_LOGIN : next;

  const loginSucceededCallback = (responseData) => {
    localStorage.setItem('accessToken', responseData.access);
    localStorage.setItem('refreshToken', responseData.refresh);
    storeSession();
    history.replace(goNext);
  };
  const signUpRequest = signUp();

  const handleModalClose = () => {
    toggleSignUpModalOpen(false);
    toggleForgotPasswordModalOpen(false);
  };

  const cardTitle = 'Log In';
  const cardContent = (
    <div className={styles.cardContent}>
      <LoginForm successCallback={loginSucceededCallback} />
      <div className={styles.extraActionsContainer}>
        <div className={styles.extraActions}>
          <Link
            onClick={toggleForgotPasswordModalOpen}
            className={styles.extraActionButton}
          >
            Forgot password
          </Link>
        </div>
        <div className={styles.extraActions}>
          Don't have an account?
          <Link
            onClick={toggleSignUpModalOpen}
            className={styles.extraActionButton}
          >
            Register
          </Link>
        </div>
      </div>
    </div>
  );

  const emailSentScreen = (
    <>
      <p>An email was sent to your email address with an activation link.</p>
      <p>Click the link in the email to complete your registration.</p>
    </>
  );

  useEffect(() => {
    if (session && redirect !== 'false') {
      history.replace(goNext);
    }
  }, []);

  return (
    <>
      <Modal
        open={forgotPasswordModalOpen}
        onClose={handleModalClose}
        title="Forgot password"
      >
        <ForgotPasswordForm />
      </Modal>
      <Modal
        open={signUpModalOpen}
        onClose={handleModalClose}
        title="Create an account"
      >
        {registrationFinished ? (
          emailSentScreen
        ) : (
          <SignUpForm
            makeRequest={signUpRequest}
            setRegistrationFinished={setRegistrationFinished}
          />
        )}
      </Modal>
      <div className={styles.container}>
        <CustomCard title={cardTitle} content={cardContent} height="auto" />
      </div>
    </>
  );
};

export default Auth;

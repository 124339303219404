import React from 'react';
import { CircularProgress } from '@material-ui/core';
import styles from './LoadingIndicator.module.scss';

const LoadingIndicator = () => (
  <div className={styles.LoadingIndicator}>
    <CircularProgress />
  </div>
);

LoadingIndicator.propTypes = {};

LoadingIndicator.defaultProps = {};

export default LoadingIndicator;

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ClearIcon from '@material-ui/icons/Clear';
import GetAppIcon from '@material-ui/icons/GetApp';
import styles from './AdditionalFiles.module.scss';
import { fetchFile, uploadFile } from '../../../services/api/api';

const AdditionalFiles = ({ files, fileUploadUrl, fieldName }) => {
  const history = useHistory();
  const [filesToDisplay, setFilesToDisplay] = useState(files);
  const makeFileUploadRequest = uploadFile(fileUploadUrl, 'patch', history);

  const getFileName = (url) => {
    const parts = url.split('media/');
    return parts && parts.length > 1 ? parts[1] : parts;
  };

  const handleDownload = (file) => () => {
    const makeFetchFileRequest = fetchFile(file, history);
    makeFetchFileRequest();
  };

  const handleDelete = (file) => () => {
    filesToDisplay.splice(filesToDisplay.indexOf(file), 1);
    setFilesToDisplay([]);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const data = {};
    data[fieldName] = file;
    makeFileUploadRequest(data).then((response) => {
      setFilesToDisplay([response[fieldName]]);
    });
  };

  return (
    <div className={styles.container}>
      {!filesToDisplay.length && (
        <>
          <label htmlFor="file-input" className={styles.uploadButton}>
            Upload
            <input
              onChange={handleFileUpload}
              type="file"
              name="file-input"
              id="file-input"
              hidden
            />
          </label>
        </>
      )}
      {filesToDisplay.map((file) => (
        <div className={styles.file} key={file}>
          <div className={styles.fileName}>{getFileName(file)}</div>
          <div>
            <a href={file}>
              <GetAppIcon
                classes={{ root: styles.button }}
                onClick={handleDownload(file)}
              />
            </a>
            <ClearIcon
              classes={{ root: styles.button }}
              onClick={handleDelete(file)}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default AdditionalFiles;

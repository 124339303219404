import React from 'react';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { persistor } from '../../../services/redux/store';
import { useLogout } from '../../../services/api/api';
import { useSession } from '../../../utils/hooks';
import LoadingIndicator from '../../common/LoadingIndicator/LoadingIndicator';
import { LOGIN_PATH } from '../../../routing/paths';

const Logout = () => {
  const history = useHistory();
  const [, , removeCookie] = useCookies(['no-cookie']);
  const { deleteSession } = useSession(null, removeCookie);
  const refreshToken = localStorage.getItem('refreshToken');

  useLogout({ refresh: refreshToken }, () => {
    deleteSession();
    persistor.purge();
    history.replace({
      pathname: LOGIN_PATH,
      search: 'redirect=false',
    });
  });

  return <LoadingIndicator />;
};

Logout.propTypes = {};

Logout.defaultProps = {};

export default Logout;

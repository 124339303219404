/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  BASE_API_URL,
  BLACKLIST_TOKEN_URL,
  COMPANY_CREATE_URL,
  COMPANY_SIZE_CHOICES_URL,
  COUNTRY_CHOICES_URL,
  FORGOT_PASSWORD_URL,
  JOIN_COMPANY_URL,
  LOGIN_URL,
  makeCompanyDetailsUrl,
  makeCompanyEventsUrl,
  makeCompanyTimeOffUrl,
  makeCompanyTimeOffInRangeUrl,
  makeConfirmEmailUrl,
  makeDeactivateEmployeeUrl,
  makeEmployeeListUrl,
  makeEventsUrl,
  makeReactivateEmployeeUrl,
  makeResendCompanyInvitationEmailUrl,
  makeTimeOffApproveUrl,
  makeTimeOffDetailsUrl,
  makeTimeOffRejectUrl,
  makeUserDetailUrl,
  makeUserTimeOffDetailUrl,
  makeUserTimeOffUrl,
  makeUserTrackerEntriesUrl,
  makeUserTrackerEntryUrl,
  MY_PROFILE_URL,
  MY_SIGNATURE_URL,
  REFRESH_TOKEN_URL,
  SIGN_UP_URL,
  TIME_OFF_TYPE_CHOICES_URL,
} from './urls';
import { LOGIN_PATH } from '../../routing/paths';
import {
  DEFAULT_HTTP_HEADERS,
  SESSION_COOKIE_NAME,
  TIME_LEFT_TO_REFRESH_SESSION,
} from '../../constants/js-constants';
import {
  fetchUserProfileBegin,
  fetchUserProfileFailure,
  updateUserProfile,
} from '../redux/slices/userProfile';
import {
  companyTimeOffFieldMapping,
  completeRegistrationFieldMapping,
  convertJsonToJS,
  convertJStoJSON,
  employeeFieldMapping,
  eventFieldMapping,
  eventsFieldMapping,
  fetchCompanyFieldMapping,
  fileFieldMapping,
  signUpFieldMapping,
  timeOffDetailsFieldMapping,
  timeOffFieldMapping,
  trackerEntryFieldMapping,
  userProfileFieldMapping,
} from '../../utils/parsers';
import {
  updateCompanySizeChoices,
  updateCountryChoices,
  updateTimeOffTypeChoices,
} from '../redux/slices/choices';
import {
  fetchCompanyBegin,
  fetchCompanyFailure,
  updateCompany,
} from '../redux/slices/company';
import {
  fetchEmployeesBegin,
  fetchEmployeesFailure,
  updateEmployees,
} from '../redux/slices/employees';
import {
  setNetworkError,
  setNetworkFinished,
  setNetworkLoading,
} from '../redux/slices/network';
import { getCookie } from '../../utils/misc';

export function useApi({
  url,
  data,
  method,
  headers,
  token,
  succResolver,
  immediate,
  errResolver,
  succCallback,
  errCallback,
}) {
  const history = useHistory();
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  const controller = new AbortController();
  const { signal } = controller;
  const abort = () => {
    controller.abort();
  };

  method = method || 'GET';
  headers = { 'Content-Type': 'application/json', ...headers };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  immediate = immediate === undefined ? true : immediate;

  const noop = (json) => json;
  const successResolver = succResolver || noop;
  const errorResolver = errResolver || noop;
  const successCallback = succCallback || noop;
  const errorCallback = errCallback || noop;

  const makeRequest = async (requestData = null) => {
    let ok = false;
    let unauthenticated = false;
    let resolvedSuccessResponse = null;
    let resolvedErrorResponse = null;

    try {
      setIsLoading(true);
      let respData = {};
      const resp = await fetch(url, {
        method,
        body: requestData ? JSON.stringify(requestData) : null,
        headers,
        signal,
      });
      if (resp.status !== 204) {
        respData = await resp.json();
      }
      if (resp.status.toString().startsWith('2')) {
        ok = true;
        resolvedSuccessResponse = successResolver(respData);
        setResponse(resolvedSuccessResponse);
      } else {
        unauthenticated = resp.status === 401;
        resolvedErrorResponse = errorResolver(respData);
        setError(resolvedErrorResponse);
      }
    } catch (fetchError) {
      setError(fetchError);
    }
    setIsLoading(false);
    if (ok) {
      successCallback(resolvedSuccessResponse);
    } else {
      if (unauthenticated) {
        history.push(LOGIN_PATH);
      }
      errorCallback(resolvedErrorResponse);
    }
  };

  useEffect(() => {
    if (immediate) {
      makeRequest(data);
    }
  }, [immediate]);

  if (immediate) {
    return { response, error, isLoading, abort };
  }

  return { response, error, isLoading, makeRequest, abort };
}

export const makeHeaders = (auth, contentType) => {
  const headers = DEFAULT_HTTP_HEADERS;
  if (auth) {
    const token = localStorage.getItem('accessToken');
    headers.Authorization = `Bearer ${token}`;
  }

  if (contentType) {
    headers['Content-Type'] = contentType;
  }
  return headers;
};

export function useFetchUserProfile() {
  const token = localStorage.getItem('accessToken');
  return useApi({
    url: BASE_API_URL + MY_PROFILE_URL,
    token,
    immediate: false,
  });
}

export function useCreateCompany(succCallback) {
  const token = localStorage.getItem('accessToken');
  return useApi({
    url: BASE_API_URL + COMPANY_CREATE_URL,
    method: 'POST',
    token,
    immediate: false,
    succCallback,
  });
}

export function useLogout(data, succCallback) {
  const token = localStorage.getItem('accessToken');
  return useApi({
    url: BASE_API_URL + BLACKLIST_TOKEN_URL,
    method: 'POST',
    token,
    immediate: true,
    data,
    succCallback,
  });
}

export function fetchUserSignature(){
  const token = localStorage.getItem('accessToken');
  const url = BASE_API_URL + MY_SIGNATURE_URL;
  const method = 'GET';
  const headers = {
    'Authorization': `Bearer ${token}`
  };

  const response = fetch(url, {
    method,
    headers
  });

  return response;
    
};

export async function uploadUserSignature(imageUrl) {
  const token = localStorage.getItem('accessToken');
  if (imageUrl) {
    const url = BASE_API_URL + MY_SIGNATURE_URL;
    const method = 'POST';
    const headers = {
      'Authorization': `Bearer ${token}`
    };
    const formData = new FormData();
    const blob = await fetch(imageUrl).then(res => res.blob());

    formData.append('file', blob, 'image.png');

    try {
      const response = await fetch(url, {
        method,
        headers,
        body: formData
      });

      if (!response.ok || !response.status.toString().startsWith('2')) {
        throw response;
      }

      // Handle success
    } catch (error) {
      console.error('Error saving signature:', error);
    }
  }
}

export async function makeFetchRequest({ url, method, data, headers }) {
  let json;
  const response = await fetch(url, {
    method,
    body: data ? JSON.stringify(data) : null,
    headers,
  });
  try {
    json = await response.json();
  } catch (error) {
    json = {};
  }
  if (!response.status.toString().startsWith('2')) {
    json.status = response.status;
    throw json;
  }
  return json;
}

export async function makeFetchFileRequest({ url, method, headers }) {
  const response = await fetch(url, {
    method,
    headers,
  });
  if (!response.ok || !response.status.toString().startsWith('2')) {
    throw response;
  }
  const file = await response.blob();
  return URL.createObjectURL(file);
}

export function redirectOnError(error, history) {
  if (error.status === 401 && !!history) {
    history.push({ pathname: LOGIN_PATH, search: 'redirect=false' });
  }
}

export function deferredFetch({
  url,
  method,
  headers,
  auth,
  contentType,
  fieldMapping,
  successCallback,
  errorCallback,
  history,
  isFile,
}) {
  headers = { ...makeHeaders(auth, contentType), ...headers };

  return async (data = null) => {
    let response;
    data = data && fieldMapping ? convertJStoJSON(data, fieldMapping) : data;
    try {
      if (isFile) {
        response = await makeFetchFileRequest({ url, method, headers });
      } else {
        response = await makeFetchRequest({ url, method, data, headers });
      }
    } catch (error) {
      if (errorCallback) {
        errorCallback(error);
      }
      redirectOnError(error, history);
      throw error;
    }
    if (!isFile && fieldMapping) {
      response = convertJsonToJS(response, fieldMapping);
    }
    if (successCallback) {
      successCallback(response);
    }
    return response;
  };
}

export function immediateFetch({
  url,
  data,
  method,
  headers,
  auth,
  fieldMapping,
  actions,
  history,
}) {
  headers = { ...makeHeaders(auth), ...headers };

  return async (dispatch) => {
    let json = {};
    try {
      dispatch(setNetworkLoading());
      json = await makeFetchRequest({ url, data, method, headers });
    } catch (error) {
      dispatch(setNetworkError(error));
      redirectOnError(error, history);
      return;
    }
    if (fieldMapping) {
      json = convertJsonToJS(json, fieldMapping);
    }
    dispatch(setNetworkFinished());
    dispatch(actions.successAction(json));
  };
}

export const cameliseString = (text) => {
  text = text.replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''));
  return text.substr(0, 1).toLowerCase() + text.substr(1);
};

export function uploadFile(url, method, auth) {
  return async (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((field) => formData.append(field, data[field]));

    const headers = makeHeaders(auth);
    delete headers['Content-Type'];
    const response = await fetch(url, {
      method,
      body: formData,
      headers,
    });

    const responseData = await response.json();

    if (!response.ok || !response.status.toString().startsWith('2')) {
      throw responseData;
    }

    return responseData;
  };
}

export function makeFormDataRequest(request) {
  if (!request) {
    return null;
  }

  const parseFormData = (dataToFormat) => {
    const formData = new FormData();
    Object.keys(dataToFormat).forEach((field) =>
      formData.append(field, dataToFormat[field])
    );
    for (var key of formData.entries()) {
      console.log(key[0] + ', ' + key[1]);
    }
    return formData;
  };

  return (reqData) => request(parseFormData(reqData));
}

export const patchUserProfile = () => {
  return deferredFetch({
    url: BASE_API_URL + MY_PROFILE_URL,
    auth: true,
    method: 'PATCH',
    fieldMapping: userProfileFieldMapping,
  });
};


export const patchUserCompanyProfile = (company_id) => {
  return deferredFetch({
    url: BASE_API_URL + MY_PROFILE_URL + `company/` + company_id,
    auth: true,
    method: 'PATCH',
    fieldMapping: userProfileFieldMapping,
  });
};

export function fetchCompanySizeChoices() {
  return deferredFetch({
    url: BASE_API_URL + COMPANY_SIZE_CHOICES_URL,
    actions: {
      successAction: updateCompanySizeChoices,
    },
    auth: true,
  });
}

export function fetchCountryChoices() {
  return deferredFetch({
    url: BASE_API_URL + COUNTRY_CHOICES_URL,
    actions: {
      successAction: updateCountryChoices,
    },
    auth: true,
  });
}

export function createCompanyEvent(companyId, history) {
  return deferredFetch({
    url: makeCompanyEventsUrl(companyId),
    method: 'POST',
    history,
    fieldMapping: eventFieldMapping,
    auth: true,
  });
}

export function fetchTimeOffTypeChoices() {
  return immediateFetch({
    url: BASE_API_URL + TIME_OFF_TYPE_CHOICES_URL,
    actions: {
      successAction: updateTimeOffTypeChoices,
    },
    auth: true,
  });
}

export function fetchUserProfile(history) {
  return () => {
    return immediateFetch({
      url: BASE_API_URL + MY_PROFILE_URL,
      fieldMapping: userProfileFieldMapping,
      actions: {
        beginAction: fetchUserProfileBegin,
        successAction: updateUserProfile,
        failAction: fetchUserProfileFailure,
      },
      history,
      auth: true,
    });
  };
}

export function fetchCompany(companyId, history) {
  return () => {
    return immediateFetch({
      url: makeCompanyDetailsUrl(companyId),
      fieldMapping: fetchCompanyFieldMapping,
      actions: {
        beginAction: fetchCompanyBegin,
        successAction: updateCompany,
        failAction: fetchCompanyFailure,
      },
      history,
      auth: true,
    });
  };
}

export function fetchUserProfileDeferred(history) {
  return deferredFetch({
    url: BASE_API_URL + MY_PROFILE_URL,
    fieldMapping: userProfileFieldMapping,
    history,
    auth: true,
  });
}

export function fetchUserCompanyProfileDeferred(companyId, history) {
  return deferredFetch({
    url: BASE_API_URL + MY_PROFILE_URL + `company/` + companyId,
    methos: 'GET',
    fieldMapping: userProfileFieldMapping,
    history,
    auth: true,
  });
}

export function patchCompany(companyId, history) {
  return deferredFetch({
    url: makeCompanyDetailsUrl(companyId),
    method: 'PATCH',
    history,
    fieldMapping: fetchCompanyFieldMapping,
    auth: true,
  });
}

export function fetchEmployees(companyId, history) {
  return () => {
    return immediateFetch({
      url: makeEmployeeListUrl(companyId),
      fieldMapping: employeeFieldMapping,
      actions: {
        beginAction: fetchEmployeesBegin,
        successAction: updateEmployees,
        failAction: fetchEmployeesFailure,
      },
      history,
      auth: true,
    });
  };
}

export function fetchCompanyEmployees(companyId, history) {
  return deferredFetch({
    url: makeEmployeeListUrl(companyId),
    fieldMapping: employeeFieldMapping,
    history,
    auth: true,
  });
}

export function fetchEvents(companyId, history) {
  return deferredFetch({
    url: makeEventsUrl(companyId),
    fieldMapping: eventsFieldMapping,
    history,
    auth: true,
  });
}

export function fetchUser(companyId, userId, history) {
  return deferredFetch({
    url: makeUserDetailUrl(companyId, userId),
    fieldMapping: userProfileFieldMapping,
    history,
    auth: true,
  });
}

export function updateUser(companyId, userId, history) {
  return deferredFetch({
    url: makeUserDetailUrl(companyId, userId),
    fieldMapping: userProfileFieldMapping,
    history,
    auth: true,
    method: 'PATCH',
  });
}

export function deactivateEmmployee(companyId, userId, history) {
  return deferredFetch({
    url: makeDeactivateEmployeeUrl(companyId, userId),
    fieldMapping: userProfileFieldMapping,
    history,
    auth: true,
    method: 'PATCH',
  });
}

export function reactivateEmployee(companyId, userId, history) {
  return deferredFetch({
    url: makeReactivateEmployeeUrl(companyId, userId),
    fieldMapping: userProfileFieldMapping,
    history,
    auth: true,
    method: 'PATCH',
  });
}

export function fetchUserTimeOff(userId, companyId, history) {
  return deferredFetch({
    url: makeUserTimeOffUrl(userId, companyId),
    method: 'GET',
    history,
    fieldMapping: timeOffFieldMapping,
    auth: true,
  });
}

export function fetchUserTrackerEntries(companyId, userId, history) {
  return deferredFetch({
    url: makeUserTrackerEntriesUrl(companyId, userId),
    method: 'GET',
    history,
    fieldMapping: trackerEntryFieldMapping,
    auth: true,
  });
}

export function deleteTrackerEntry(companyId, userId, entryId, history) {
  return deferredFetch({
    url: makeUserTrackerEntryUrl(companyId, userId, entryId),
    method: 'DELETE',
    history,
    fieldMapping: trackerEntryFieldMapping,
    auth: true,
  });
}

export function createUserTrackEntry(companyId, userId, history) {
  return deferredFetch({
    url: makeUserTrackerEntriesUrl(companyId, userId),
    method: 'POST',
    history,
    fieldMapping: trackerEntryFieldMapping,
    auth: true,
  });
}

export function updateUserTrackEntry(companyId, userId, trackEntryId, history) {
  return deferredFetch({
    url: makeUserTrackerEntryUrl(companyId, userId, trackEntryId),
    method: 'PATCH',
    history,
    fieldMapping: trackerEntryFieldMapping,
    auth: true,
  });
}

export function fetchCompanyTimeOff(companyId, history) {
  return deferredFetch({
    method: 'GET',
    url: makeCompanyTimeOffUrl(companyId),
    history,
    fieldMapping: companyTimeOffFieldMapping,
    auth: true,
  });
}

export function fetchCompanyTimeOffInRange(companyId, starts_at, ends_at, history) {
  return deferredFetch({
    method: 'GET',
    url: makeCompanyTimeOffInRangeUrl(companyId, starts_at, ends_at),
    history,
    fieldMapping: companyTimeOffFieldMapping,
    auth: true,
  });
}

export function createEmployee(companyId, history) {
  return deferredFetch({
    url: makeEmployeeListUrl(companyId),
    method: 'POST',
    history,
    fieldMapping: employeeFieldMapping,
    auth: true,
  });
}

export function editTimeOff(companyId, timeOffId, history, contentType) {
  return deferredFetch({
    url: makeUserTimeOffDetailUrl(companyId, timeOffId),
    method: 'PATCH',
    history,
    auth: true,
    contentType,
  });
}

export function deleteTimeOff(companyId, timeOffId, history) {
  return deferredFetch({
    url: makeUserTimeOffDetailUrl(companyId, timeOffId),
    method: 'DELETE',
    history,
    fieldMapping: timeOffFieldMapping,
    auth: true,
  });
}

export function addTimeOff(companyId, userId, history) {
  return deferredFetch({
    url: makeUserTimeOffUrl(companyId, userId),
    method: 'POST',
    history,
    fieldMapping: timeOffFieldMapping,
    auth: true,
  });
}

export function fetchFile(fileUrl, history) {
  return deferredFetch({
    url: fileUrl,
    method: 'GET',
    history,
    fieldMapping: fileFieldMapping,
    auth: true,
    isFile: true,
  });
}

export function confirmEmail(token) {
  return deferredFetch({
    url: makeConfirmEmailUrl(token),
    method: 'POST',
  });
}

export function completeRegistration(url) {
  return deferredFetch({
    url: BASE_API_URL + url,
    method: 'PUT',
    fieldMapping: completeRegistrationFieldMapping,
  });
}

export function joinCompany() {
  return deferredFetch({
    url: BASE_API_URL + JOIN_COMPANY_URL,
    method: 'POST',
  });
}

export function resendInvitation(companyId, userId) {
  return deferredFetch({
    url: makeResendCompanyInvitationEmailUrl(companyId, userId),
    method: 'POST',
    auth: true,
  });
}

export const forgotPassword = () => {
  return deferredFetch({
    url: BASE_API_URL + FORGOT_PASSWORD_URL,
    method: 'POST',
    auth: false,
  });
};

export function fetchTimeOffDetails(companyId, timeOffId, token) {
  return deferredFetch({
    url: makeTimeOffDetailsUrl(companyId, timeOffId, token),
    method: 'GET',
    auth: true,
    fieldMapping: timeOffDetailsFieldMapping,
  });
}

export function timeOffApprove(companyId, timeOffId, token = null) {
  return deferredFetch({
    url: makeTimeOffApproveUrl(companyId, timeOffId, token),
    method: 'POST',
    auth: token === null,
  });
}

export function timeOffReject(companyId, timeOffId, token = null) {
  return deferredFetch({
    url: makeTimeOffRejectUrl(companyId, timeOffId, token),
    method: 'POST',
    auth: token === null,
  });
}

export function signUp() {
  return deferredFetch({
    url: BASE_API_URL + SIGN_UP_URL,
    method: 'POST',
    fieldMapping: signUpFieldMapping,
  });
}

export function signIn() {
  return deferredFetch({
    url: BASE_API_URL + LOGIN_URL,
    method: 'POST',
  });
}

export function makeRefreshToken(storeSession) {
  const makeRequest = deferredFetch({
    url: BASE_API_URL + REFRESH_TOKEN_URL,
    method: 'POST',
  });

  return () => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      return;
    }

    const activeSessionCookie = getCookie(SESSION_COOKIE_NAME);
    const now = new Date().getTime();
    if (
      activeSessionCookie &&
      now >= activeSessionCookie - TIME_LEFT_TO_REFRESH_SESSION
    ) {
      makeRequest({ refresh: refreshToken }).then((response) => {
        localStorage.setItem('accessToken', response.access);
        storeSession();
      });
    }
  };
}

export const SESSION_COOKIE_TTL = 24 * 60 * 60;
export const SESSION_COOKIE_NAME = 'activeSession';
// Refresh the session when this amount of milliseconds is left before expiry
export const TIME_LEFT_TO_REFRESH_SESSION = 60000;
export const PHONE_NUMBER_REGEX = /^\+?[0-9]+$/;
export const ZIP_CODE_REGEX = /^[0-9]*(-?[0-9]+)?$/;
export const DEFAULT_HTTP_HEADERS = { 'Content-Type': 'application/json' };
export const DATE_FORMAT = 'dd/MM/yyyy';
export const MOMENT_DATE_FORMAT = 'DD/MM/YYYY';
export const MOMENT_DATETIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';
export const REQUIRED_FIELD_ERROR = 'This field is required';

import { Button, Tab, Tabs } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styles from './EmployeeDetails.module.scss';
import { fetchUser } from '../../../../services/api/api';
import CustomCard from '../../../common/CustomCard/CustomCard';
import LoadingIndicator from '../../../common/LoadingIndicator/LoadingIndicator';
import EmployeeDetailsCard from './EmployeeDetailsCard/EmployeeDetailsCard';
import EmployeeTimeOff from '../EmployeeTimeOff/EmployeeTimeOff';
import TrackerTimer from '../../Tracker/TrackerTimer/TrackerTimer';

const EmployeeDetails = () => {
  const { companyId, employeeId } = useParams();
  const history = useHistory();
  const [employee, setEmployee] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedTab, selectTab] = useState('overview');
  const [shownContent, setShownContent] = useState(<></>);

  const fetchEmployee = fetchUser(companyId, employeeId, history);
  let cardContent = '';
  let cardTitle = 'Employee details';
  const makeEmployeeCard = (user) => (
    <EmployeeDetailsCard
      employee={user}
      companyId={companyId}
      editable={false}
      callback={setEmployee}
    />
  );

  useEffect(() => {
    fetchEmployee()
      .then((employeeData) => {
        setEmployee(employeeData);
        setLoading(false);
        setShownContent(makeEmployeeCard(employeeData));
      })
      .catch((errorData) => {
        setLoading(false);
        setError(errorData);
      });
  }, []);

  const makeTitle = (text) => <div className={styles.title}>{text}</div>;

  const handleTabSelect = (event, newValue) => {
    if (newValue === 'timeOff') {
      setShownContent(
        <EmployeeTimeOff
          companyId={companyId}
          employee={employee}
          callback={setEmployee}
        />
      );
    } else if (newValue === 'overview') {
      setShownContent(makeEmployeeCard(employee));
    } else if (newValue === 'tracker') {
      setShownContent(<TrackerTimer user={employee} />);
    }
    selectTab(newValue);
  };

  if (loading) {
    cardContent = <LoadingIndicator />;
  }

  if (employee) {
    cardTitle = employee.fullName;
    cardContent = (
      <>
        <div className={styles.tabsContainer}>
          <Tabs
            onChange={handleTabSelect}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            value={selectedTab}
            className={styles.tabSelector}
          >
            <Tab
              classes={{ root: styles.tab }}
              label="Overview"
              value="overview"
            />
            <Tab
              classes={{ root: styles.tab }}
              label="Time Off"
              value="timeOff"
            />
            <Tab
              classes={{ root: styles.tab }}
              label="Tracker"
              value="tracker"
            />
          </Tabs>
        </div>
        {shownContent}
      </>
    );
  }

  if (error) {
    cardContent = 'Something went wrong while fetching data from the server.';
  }

  return (
    <CustomCard
      title={cardTitle}
      content={cardContent}
      actions={
        <Button variant="contained" color="primary" onClick={history.goBack}>
          Go back
        </Button>
      }
    />
  );
};

export default EmployeeDetails;

import { createSlice } from '@reduxjs/toolkit';

export const userTimeOffSlice = createSlice({
  name: 'userTimeOff',
  initialState: null,
  reducers: {
    updateUserTimeOff: (state, action) => {
      return {
        loading: false,
        error: null,
        data: action.payload,
      };
    },
    addUserTimeOff: (state, action) => {
      state.data.push(action.payload);
      return state;
    },
    fetchUserTimeOffBegin: () => {
      return { loading: true, data: null, error: null };
    },
    fetchUserTimeOffFailure: (state, action) => {
      return { loading: false, error: action.payload, data: null };
    },
  },
});

export const {
  updateUserTimeOff,
  addUserTimeOff,
  fetchUserTimeOffBegin,
  fetchUserTimeOffFailure,
} = userTimeOffSlice.actions;

export const userTimeOffSelector = (state) => state.userTimeOff ? state.userTimeOff.data : {};
export const userTimeOffeErrorSelector = (state) => state.userTimeOff ? state.userTimeOff.error : {};
export const userTimeOffLoadingSelector = (state) => state.userTimeOff ? state.userTimeOff.loading : {};

export default userTimeOffSlice.reducer;

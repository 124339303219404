import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { useHistory } from 'react-router-dom';
import styles from './EmployeeDetailsCard.module.scss';
import Avatar from '../../../../common/Avatar/Avatar';
import { resendInvitation, updateUser } from '../../../../../services/api/api';
import Modal from '../../../../common/Modal/Modal';
import UserEditForm from '../../../../common/UserEditForm/UserEditForm';

const EmployeeDetailsCard = ({ employee, companyId, callback }) => {
  const history = useHistory();
  const [invitationSuccess, setInvitationSuccess] = useState(false);
  const [employeeData, setEmployeeData] = useState(employee);
  const resendInvitationRequest = resendInvitation(companyId, employeeData.id);
  const makeResetInvitation = () => {
    setInvitationSuccess(false);
    resendInvitationRequest({}).then(() => setInvitationSuccess(true));
  };
  const makeUpdateRequest = updateUser(companyId, employeeData.id, history);
  const [modalOpen, setEditModalOpen] = useState(false);

  const handleModalOpen = () => {setEditModalOpen(true);}
  const handleModalClose = () => setEditModalOpen(false);


  const handleEmployeeUpdate = (data) => {
    makeUpdateRequest(data).then((response) => {
      setEmployeeData(response);
      callback(response);
    });
  };

  const handleActivation = (value) => () => {
    makeUpdateRequest({ deactivated: value }).then((response) =>
      setEmployeeData(response)
    );
  };

  return (
    <>
      <Modal open={modalOpen} onClose={handleModalClose}>
        <UserEditForm
          userProfile={employeeData}
          saveCallback={handleEmployeeUpdate}
          is_manager={employeeData.isUserManager}
        />
      </Modal>
      <div>
        <div className={styles.userSummary}>
          <Avatar
            name={employeeData.fullName}
            width="110px"
            height="110px"
            initials
            url={employeeData.avatar}
          />
          <div className={styles.profileData}>
            <div className={styles.profileField}>
              <b className={styles.profileFieldName}>Name: </b>
              {employeeData.fullName}
            </div>
            <div className={styles.profileField}>
              <b className={styles.profileFieldName}>Status: </b>
              {employeeData.status}
              {employeeData.status === 'Invited' && (
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  className={styles.resendInvitationButton}
                  onClick={makeResetInvitation}
                >
                  Resend Invitation
                </Button>
              )}
              <Button
                type="submit"
                variant="contained"
                size="small"
                className={styles.resendInvitationButton}
                onClick={handleActivation(!employeeData.deactivated)}
              >
                {employeeData.deactivated ? 'Reactivate' : 'Deactivate'}
              </Button>
              {invitationSuccess && <CheckIcon />}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.userGeneralInfo}>
        <h3>Work Info</h3>
        <div className={styles.profileFied}>
          <b className={styles.profileFieldName}>Job Title: </b>
          {employeeData.jobTitle || '-'}
        </div>
        <div className={styles.profileFied}>
          <b className={styles.profileFieldName}>Date Joined: </b>
          {employeeData.dateJoined || '-'}
        </div>
        <div className={styles.profileFied}>
          <b className={styles.profileFieldName}>Manager: </b>
          {employeeData.manager ? employeeData.manager.fullName : '-'}
        </div>
      </div>
      <div className={styles.userGeneralInfo}>
        <h3>Personal Info</h3>
        <div className={styles.profileFied}>
          <b className={styles.profileFieldName}>Email: </b>
          {employeeData.email || '-'}
        </div>
        <div className={styles.profileFied}>
          <b className={styles.profileFieldName}>Phone number: </b>
          {employeeData.phoneNumber || '-'}
        </div>
        <div className={styles.profileFied}>
          <b className={styles.profileFieldName}>Address: </b>
          {employeeData.address || '-'}
        </div>
        <div className={styles.profileFied}>
          <b className={styles.profileFieldName}>Birth Date: </b>
          {employeeData.birthDate || '-'}
        </div>
      </div>
      {employeeData.canEdit && !employeeData.deactivated && (
        <div className={styles.buttonContainer}>
          <Button variant="contained" color="primary" onClick={handleModalOpen}>
            Edit
          </Button>
        </div>
      )}
    </>
  );
};

export default EmployeeDetailsCard;

import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from '@material-ui/core';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import styles from './CreateEmployeeForm.module.scss';
import FormErrors from '../../../common/FormErrors/FormErrors';

const CreateEmployeeForm = ({ request, closeModal, callback }) => {
  const [apiErrors, setApiErrors] = useState({});
  const formik = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      reportsToMe: false,
      inviteToApp: true,
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required(),
      email: Yup.string().email().required(),
      reportsToMe: Yup.boolean(),
      inviteToApp: Yup.boolean(),
    }),
    onSubmit: (value) => {
      request(value)
        .then((employee) => {
          callback(employee);
          closeModal();
        })
        .catch((error) => setApiErrors(error));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormGroup>
        <div>
          <TextField
            id="fullName"
            label="Name"
            type="text"
            variant="outlined"
            margin="normal"
            autoComplete="name"
            fullWidth
            required
            classess={{ root: styles.fullLengthField }}
            onChange={formik.handleChange}
            value={formik.values.fullName}
            onBlur={formik.onBlur}
          />
        </div>
      </FormGroup>
      <FormGroup>
        <div>
          <TextField
            id="email"
            label="Email"
            type="text"
            variant="outlined"
            margin="normal"
            autoComplete="email"
            fullWidth
            required
            classes={{ root: styles.fullLengthField }}
            onChange={formik.handleChange}
            value={formik.values.email}
            onBlur={formik.onBlur}
          />
        </div>
        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values.inviteToApp}
              onChange={formik.handleChange}
              name="inviteToApp"
              color="primary"
            />
          }
          label="Send invitation to join the app"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values.reportsToMe}
              onChange={formik.handleChange}
              name="reportsToMe"
              color="primary"
            />
          }
          label="Reports to me"
        />
      </FormGroup>
      <FormErrors apiErrors={apiErrors} />
      <div className={styles.buttonContainer}>
        <Button type="submit" variant="contained" color="primary">
          Create employee
        </Button>
      </div>
    </form>
  );
};

export default CreateEmployeeForm;

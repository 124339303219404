import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  fetchUserCompanyProfileDeferred,
  patchUserCompanyProfile,
} from '../../../services/api/api';
import CustomCard from '../../common/CustomCard/CustomCard';
import LoadingIndicator from '../../common/LoadingIndicator/LoadingIndicator';
import UserEditForm from '../../common/UserEditForm/UserEditForm';
import { selectedCompanySelector } from '../../../services/redux/slices/company';
import { userProfileSelector } from '../../../services/redux/slices/userProfile';

const Profile = () => {
  const userProfile = useSelector(userProfileSelector);
  const storedSelectedCompany = useSelector(selectedCompanySelector);
  const selectedCompany = userProfile.companies[0].id;

  const updateProfile = patchUserCompanyProfile(selectedCompany);
  const fetchProfile =   fetchUserCompanyProfileDeferred(selectedCompany);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    fetchProfile().then((response) => setProfile(response));
  }, []);

  const handleSave = (data) => {
    updateProfile(data).then((response) => {
      setProfile(response);
    });
  };

  if (!profile && profile === null) {
    return <LoadingIndicator />;
  }

  return (
    <CustomCard
      title="My Profile"
      content={<UserEditForm userProfile={profile} saveCallback={handleSave} />}
    />
  );
};

export default Profile;

import React from 'react';
import { TextField, FormGroup, MenuItem, Button } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import styles from './CreateCompanyForm.module.scss';
import {
  convertJStoJSON,
  companyCreateFieldMapping,
} from '../../../../utils/parsers';
import {
  ZIP_CODE_REGEX,
  PHONE_NUMBER_REGEX,
} from '../../../../constants/js-constants';
import { useCreateCompany } from '../../../../services/api/api';
import { DASHBOARD_PATH } from '../../../../routing/paths';
import FormInput from '../../../common/FormInput/FormInput';
import { updateSelectedCompany } from '../../../../services/redux/slices/company';

const CreateCompanyForm = ({ history, companySizeChoices, countryChoices }) => {
  const dispatch = useDispatch();
  const { makeRequest } = useCreateCompany((data) => {
    dispatch(updateSelectedCompany(data));
    history.push(DASHBOARD_PATH);
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      size: '',
      address: '',
      country: '',
      zipCode: '',
      contactPhone: '',
      contactEmail: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      size: Yup.string().required(),
      address: Yup.string().required(),
      country: Yup.string().required(),
      zipCode: Yup.string().matches(
        ZIP_CODE_REGEX,
        'Only numbers and "-" allowed'
      ),
      contactPhone: Yup.string()
        .matches(PHONE_NUMBER_REGEX, 'Only numers and "+" allowed.')
        .required(),
      contactEmail: Yup.string().email().required(),
    }),
    onSubmit: (value) => {
      makeRequest(convertJStoJSON(value, companyCreateFieldMapping));
    },
  });

  const sizeChoicesDom = companySizeChoices.map((choice) => (
    <MenuItem value={choice.choice_code} key={choice.choice_code}>
      {choice.choice_label}
    </MenuItem>
  ));

  const countryChoicesDom = countryChoices.map((choice) => (
    <MenuItem value={choice.choice_code} key={choice.choice_code}>
      {choice.choice_label}
    </MenuItem>
  ));

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormGroup row classes={{ root: styles.inlineFormGroup }}>
        <div className={styles.inputContainer}>
          <FormInput
            type="text"
            fieldName="name"
            label="Name"
            required
            fullLength
            autoComplete="organization"
            formikObject={formik}
          />
        </div>
        <div className={styles.inputContainer}>
          <FormInput
            type="select"
            fieldName="size"
            label="Company Size"
            required
            handleChange={formik.handleChange('size')}
            formikObject={formik}
            choices={sizeChoicesDom}
            fullLength
          />
        </div>
      </FormGroup>
      <FormGroup>
        <FormInput
          type="text"
          fieldName="address"
          label="Address"
          required
          autoComplete="street-address"
          formikObject={formik}
        />
      </FormGroup>
      <FormGroup row classes={{ root: styles.inlineFormGroup }}>
        <div className={styles.inputContainer}>
          <FormInput
            type="select"
            label="Country"
            fieldName="country"
            required
            handleChange={formik.handleChange('country')}
            formikObject={formik}
            choices={countryChoicesDom}
            fullLength
            autoComplete="country"
          />
        </div>
        <div className={styles.inputContainer}>
          <FormInput
            type="text"
            fieldName="zipCode"
            label="Zip Code"
            required
            fullLength
            autoComplete="postal-code"
            formikObject={formik}
          />
        </div>
      </FormGroup>
      <FormGroup>
        <FormInput
          type="text"
          fieldName="contactPhone"
          label="Contact Phone"
          required
          autoComplete="tel"
          formikObject={formik}
        />
        <FormInput
          type="text"
          fieldName="contactEmail"
          label="Contact Email"
          required
          autoComplete="email"
          formikObject={formik}
        />
      </FormGroup>
      <div className={styles.buttonContainer}>
        <Button type="submit" variant="contained" color="primary">
          Create company
        </Button>
      </div>
    </form>
  );
};

export default CreateCompanyForm;

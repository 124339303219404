import { createSlice } from '@reduxjs/toolkit';

export const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState: null,
  reducers: {
    updateUserProfile: (state, action) => {
      return {
        loading: false,
        error: null,
        data: action.payload,
      };
    },
    fetchUserProfileBegin: () => {
      return { loading: true, data: null, error: null };
    },
    fetchUserProfileFailure: (state, action) => {
      return { loading: false, error: action.payload, data: null };
    },
  },
});

export const {
  updateUserProfile,
  fetchUserProfileBegin,
  fetchUserProfileFailure,
} = userProfileSlice.actions;

export const userProfileSelector = (state) => state.userProfile ? state.userProfile.data : {};
export const userProfileErrorSelector = (state) => state.userProfile ? state.userProfile.error : {};
export const userProfileLoadingSelector = (state) => state.userProfile ? state.userProfile.loading : {};

export default userProfileSlice.reducer;

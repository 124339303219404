import React from 'react';
import Alert from '@material-ui/lab/Alert';

const ApiError = (error) => {
  const errorMesage =
    error.detail ||
    error.message ||
    error.error.detail ||
    error.error.message ||
    JSON.stringify(error);
  return <Alert severity="error">{errorMesage}</Alert>;
};

export default ApiError;

export const checkObjectIsEmpty = (obj) => {
  return !obj || (Object.keys(obj).length === 0 && obj.constructor === Object);
};

export const getCookie = (cookieName) => {
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookies = decodedCookie.split(';');

  const searchedCookie = cookies.find((cookie) => {
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    return cookie.indexOf(name) === 0;
  });

  return searchedCookie
    ? searchedCookie.substring(name.length, searchedCookie.length)
    : null;
};

export const getSelectedCompany = () => {

}

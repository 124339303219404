import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import queryString from 'query-string';
import { Button } from '@material-ui/core';
import {
  fetchTimeOffDetails,
  timeOffApprove,
  timeOffReject,
} from '../../../services/api/api';
import CustomCard from '../../common/CustomCard/CustomCard';
import LoadingIndicator from '../../common/LoadingIndicator/LoadingIndicator';
import styles from './TimeOffApproval.module.scss';
import ApiError from '../../common/ApiError/ApiError';
import TimeOffDetails from '../../common/TimeOffDetails/TimeOffDetails';

const TimeOffApproval = ({ location }) => {
  const { companyId, timeOffId } = useParams();
  const [loading, setLoading] = useState(false);
  const [isApproved, setApproved] = useState(false);
  const [isRejected, setRejected] = useState(false);
  const [error, setError] = useState(null);
  const [approvalError, setApprovalError] = useState(null);
  const [timeOff, setTimeOff] = useState(null);
  const { action, token } = queryString.parse(location.search);

  useEffect(() => {
    if (token && action) {
      if (action === 'approve') {
        const approveRequest = timeOffApprove(companyId, timeOffId, token);
        approveRequest()
          .then(() => setApproved(true))
          .catch((err) => {
            setError(err);
            console.log(err);
          });
      } else {
        const rejectRequest = timeOffReject(companyId, timeOffId, token);
        rejectRequest()
          .then(() => setRejected(true))
          .catch((err) => setError(err));
      }
    }
    const fetchTimeOff = fetchTimeOffDetails(companyId, timeOffId, token);

    fetchTimeOff()
      .then((response) => {
        setLoading(false);
        setTimeOff(response);
      })
      .catch((fetchError) => {
        setError(fetchError);
        setLoading(false);
      });
  }, []);

  const approveTimeOff = () => {
    const approveRequest = timeOffApprove(companyId, timeOff.id);
    approveRequest()
      .then(() => setApproved(true))
      .catch((approveError) => setApprovalError(approveError));
  };

  const rejectTimeOff = () => {
    const rejectRequest = timeOffReject(companyId, timeOff.id);
    rejectRequest()
      .then(() => setRejected(true))
      .catch((rejectError) => setApprovalError(rejectError));
  };

  let cardTitle = 'You have a new time-off request';
  let cardContent;

  if (timeOff && timeOff.needsApproval) {
    const timeOffStatus = (
      <Alert>
      {error ? (
        error.detail
      ) : (
        `This request is ${timeOff.isApproved || isApproved ? 'approved' : 'rejected'}`
      )}
    </Alert>
    );
    cardContent = (
      <div className={styles.timeOffDetailsContainer}>
        <TimeOffDetails timeOff={timeOff} />
        {!timeOff.isApproved &&
        !timeOff.isRejected &&
        !isApproved &&
        !isRejected ? (
          <>
            <div className={styles.buttonContainer}>
              <div className={styles.buttons}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={approveTimeOff}
                >
                  Approve
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={rejectTimeOff}
                >
                  Reject
                </Button>
              </div>
            </div>
            <div className={styles.statusContainer}>
              {approvalError && <ApiError error={approvalError} />}
            </div>
          </>
        ) : (
          <div className={styles.statusContainer}>{timeOffStatus}</div>
        )}
      </div>
    );
  } else if (timeOff && !timeOff.needsApproval) {
    cardContent = 'This kind of time off does not need approval.';
    cardTitle = '';
  } else if (loading) {
    cardContent = <LoadingIndicator />;
  } else if (error) {
    cardContent = (
      <div className={styles.timeOffErrorContainer}>
        {error.status === 400 ? error.detail : 'Time off not found'}
      </div>
    );
    cardTitle = '';
  }

  return <CustomCard title={cardTitle} content={cardContent} />;
};

export default TimeOffApproval;
import company from '../redux/slices/company';

export const BASE_API_URL =
  process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000/api/';

// Paths
export const LOGIN_URL = 'token/';
export const REFRESH_TOKEN_URL = 'token/refresh/';
export const BLACKLIST_TOKEN_URL = 'token/blacklist/';
export const LOGOUT_URL = 'token/revoke';
export const SIGN_UP_URL = 'sign-up/';

export const MY_PROFILE_URL = 'profile/';
export const MY_SIGNATURE_URL = 'signature/';
export const COMPANY_SIZE_CHOICES_URL = 'company-size-choices/';
export const COUNTRY_CHOICES_URL = 'country-choices/';
export const TIME_OFF_TYPE_CHOICES_URL = 'time-off-type-choices/';
export const COMPANY_CREATE_URL = 'companies/';
export const MY_COMPANY_URL = 'companies/';
export const COMPLETE_REGISTRATION_URL = 'complete-registration/';
export const PASSWORD_RESET_URL = 'reset-password/';
export const FORGOT_PASSWORD_URL = 'forgot-password/';
export const JOIN_COMPANY_URL = 'accept-company-invitation/';

export const makeEmployeeListUrl = (companyId) =>
  `${BASE_API_URL}${MY_COMPANY_URL}${companyId.toString()}/employees/`;

export const makeUserTimeOffUrl = (companyId, userId) =>
  `${BASE_API_URL}companies/${companyId}/users/${userId}/time-off/`;

export const makeUserTimeOffDetailUrl = (companyId, timeOffId) =>
  `${BASE_API_URL}companies/${companyId}/time-off/${timeOffId}/`;

export const makeConfirmEmailUrl = (token) =>
  `${BASE_API_URL}confirm-email/?token=${token}`;

export const makeCompanyTimeOffUrl = (companyId) =>
  `${BASE_API_URL}companies/${companyId}/time-off/`;

export const makeCompanyTimeOffInRangeUrl = (companyId, starts_at, ends_at) =>
  `${BASE_API_URL}companies/${companyId}/time-off/date-range/${starts_at}/${ends_at}/`;

export const makeTimeOffDetailsUrl = (companyId, timeOffId, token) =>
  `${BASE_API_URL}companies/${companyId}/time-off/${timeOffId}/${
    token ? '?token=' + token : ''
  }`;

export const makeTimeOffApproveUrl = (companyId, timeOffId, token) => {
  const url = `${BASE_API_URL}companies/${companyId}/time-off/${timeOffId}/approve/`;
  if (token) {
    return `${url}?token=${token}`;
  }
  return url;
};

export const makeUserTrackerEntriesUrl = (companyId, userId) =>
  `${BASE_API_URL}companies/${companyId}/users/${userId}/tracker-entries/`;

export const makeUserTrackerEntryUrl = (companyId, userId, trackerEntryId) =>
  `${BASE_API_URL}companies/${companyId}/users/${userId}/tracker-entries/${trackerEntryId}/`;

export const makeFileUrl = (fileName) => `${BASE_API_URL}media/${fileName}`;

export const makeCompanyEventsUrl = (companyId) =>
  `${BASE_API_URL}${MY_COMPANY_URL}${companyId}/events/`;

export const makeCompanyDetailsUrl = (companyId) =>
  `${BASE_API_URL}${MY_COMPANY_URL}${companyId}/`;

export const makeTimeOffRejectUrl = (companyId, timeOffId, token) => {
  const url = `${BASE_API_URL}companies/${companyId}/time-off/${timeOffId}/reject/`;
  if (token) {
    return `${url}?token=${token}`;
  }
  return url;
};

export const makeResendCompanyInvitationEmailUrl = (companyId, userId) => {
  return `${BASE_API_URL}companies/${companyId}/users/${userId}/resend-invitation/`;
};

export const makeEventsUrl = (companyId) =>
  `${BASE_API_URL}companies/${companyId}/events/`;

export const makeUserDetailUrl = (companyId, userId) =>
  `${BASE_API_URL}companies/${companyId}/users/${userId}/`;

export const makeDeactivateEmployeeUrl = (companyId, userId) =>
  `${BASE_API_URL}companies/${companyId}/users/${userId}/deactivate`;

export const makeReactivateEmployeeUrl = (companyId, userId) =>
  `${BASE_API_URL}companies/${companyId}/users/${userId}/reactivate`;

export const makeFilteringParams = (options) => {
  const ordering = options.ordering ? `ordering=${options.ordering}` : '';
  const search = options.search ? `search=${options.search}` : '';
  const params = `${ordering ? `${ordering}&` : ''}${search || ''}`;
  return `${params ? '?' : ''}${params}`;
};

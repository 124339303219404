import React from 'react';
import { Card, CardHeader, CardContent, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import styles from './NoCompanyFound.module.scss';
import { CREATE_COMPANY_PATH } from '../../../routing/paths';

const NoCompanyFound = () => {
  const history = useHistory();

  function handleCreateCompanyClick() {
    history.push(CREATE_COMPANY_PATH);
  }

  return (
    <div className={styles.container}>
      <Card className={styles.card}>
        <CardHeader className={styles.CardHeader} title="Add a company" />
        <CardContent classes={{ root: styles.cardContent }}>
          <p>It appears you don't belong to a company.</p>
          <p>
            If you are an employee, and your company is already registered with
            us, ask a manager to send you an invitation.
          </p>
          <p>Otherwise, click here to create a company:</p>
        </CardContent>
        <div className={styles.buttonContainer}>
          <Button
            onClick={handleCreateCompanyClick}
            variant="contained"
            color="primary"
          >
            Create a company
          </Button>
        </div>
      </Card>
    </div>
  );
};

NoCompanyFound.propTypes = {};

NoCompanyFound.defaultProps = {};

export default NoCompanyFound;

import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import styles from './CompanyEvents.module.scss';
import TimeOffListItem from '../../../common/TimeOffListItem/TimeOffListItem';
import CreateEventModal from './CreateEventModal/CreateEventModal';

const CompanyEvents = ({ company, updateCompany }) => {
  const companyEvents =
    company.events && company.events.length
      ? company.events.map((event, index) => {
          return { key: `${index}-${event.typeDisplay}`, ...event };
        })
      : [];

  const [events, setEvents] = useState(companyEvents || []);
  const [modalOpen, setModalOpen] = useState(false);

  const deleteEvent = (eventToDelete) => () => {
    const eventsToSend = events.filter(
      (event) => event.key !== eventToDelete.key
    );
    setEvents(eventsToSend);
    updateCompany({ events: eventsToSend });
  };

  const addEvent = (event) => {
    events.push(event);
    setEvents(events);
  };

  const handleModalOpen = () => setModalOpen(!modalOpen);

  return (
    <>
      <CreateEventModal
        companyId={company.id}
        open={modalOpen}
        onClose={handleModalOpen}
        addEvent={addEvent}
      />
      {company.canEdit && (
        <div className={styles.addEventButton}>
          <Button variant="contained" color="primary" onClick={handleModalOpen}>
            <AddIcon />
            Add event
          </Button>
        </div>
      )}
      <div className={styles.eventsContainer}>
        <div className={styles.event}>
          {company.events && company.events.length ? (
            events.map((event) => (
              <div key={event.key}>
                <TimeOffListItem
                  timeOff={event}
                  extraActionName={
                    !event.legalHoliday && company.canEdit && 'Remove'
                  }
                  extraActionHandler={company.canEdit && deleteEvent(event)}
                />
              </div>
            ))
          ) : (
            <div>There are no events for your company.</div>
          )}
        </div>
      </div>
    </>
  );
};

export default CompanyEvents;

import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { useSelector } from 'react-redux';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router';
import {
  createUserTrackEntry,
  deleteTrackerEntry,
  fetchUserTrackerEntries,
  updateUserTrackEntry,
} from '../../../../services/api/api';
import { selectedCompanySelector } from '../../../../services/redux/slices/company';
import { userProfileSelector } from '../../../../services/redux/slices/userProfile';
import styles from './TrackerTimer.module.scss';
import {
  MOMENT_DATETIME_FORMAT,
  DATE_FORMAT,
} from '../../../../constants/js-constants';
import Modal from '../../../common/Modal/Modal';
import NoCompanyFound from '../../NoCompanyFound/NoCompanyFound';
import Alert from '@material-ui/lab/Alert';

const TrackerTimer = ({ user }) => {
  const [trackerEntries, setTrackerEntries] = useState([]);
  const [runningEntry, setRunningEntry] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [interval, updateInterval] = useState(null);
  const [entryDayMapping, setEntryDayMapping] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [deletingEntry, setDeletingEntry] = useState(null);
  const [addingEntry, setAddingEntry] = useState(null);
  const [newEntryDate, setNewEntryDate] = useState(moment());
  const [timeError, setTimeError] = useState(null);
  const [newEntryStartTime, setNewEntryStartTime] = useState(
    moment({ hour: 8, minute: 0 })
  );
  const [newEntryEndTime, setNewEntryEndTime] = useState(
    moment({ hour: 16, minute: 0 })
  );

  const history = useHistory();
  let userProfile = useSelector(userProfileSelector);
  const storedSelectedCompany = useSelector(selectedCompanySelector);
  const selectedCompany = storedSelectedCompany || userProfile.companies[0];

  const utcOffset = -new Date().getTimezoneOffset();

  userProfile = user || userProfile;

  const createInterval = () => {
    if (interval) {
      clearInterval(interval);
    }
    updateInterval(
      setInterval(() => {
        setElapsedTime((time) => time + 1);
      }, 1000)
    );
  };

  const calculateDateDiff = (startsAt, endsAt) =>
    moment.duration(endsAt.diff(startsAt)).asSeconds();

  const addToMapping = (mapping, item) => {
    const newMapping = item;
    Object.keys(mapping).forEach((key) => {
      newMapping[key] = mapping[key];
    });
    return newMapping;
  };

  const handleDelete = (entry) => () => {
    setDeletingEntry(entry);
    setConfirmationOpen(!confirmationOpen);
  };

  const deleteEntry = (id) => () => {
    const deleteEntryRequest = deleteTrackerEntry(
      selectedCompany.id,
      userProfile.id,
      id,
      history
    );
    deleteEntryRequest().then(() => {
      const entries = trackerEntries.filter((entry) => entry.id !== id);
      let mapping = {};
      setTrackerEntries(entries);
      entries.forEach((entry) => {
        const date = moment
          .utc(entry.startsAt, MOMENT_DATETIME_FORMAT)
          .format('DD MMM');
        if (date in mapping) {
          mapping[date].push(entry);
        } else {
          const newEntry = {};
          newEntry[date] = [entry];
          mapping = addToMapping(mapping, newEntry);
        }
      });
      setEntryDayMapping(mapping);
      setConfirmationOpen(false);
    });
  };

  const fetchNewEntries = () => {
    const fetchTrackerEntriesRequest = fetchUserTrackerEntries(
      selectedCompany.id,
      userProfile.id,
      history
    );

    fetchTrackerEntriesRequest().then((response) => {
      let mapping = {};
      response.reverse();
      response.forEach((entry) => {
        const date = moment
          .utc(entry.startsAt, MOMENT_DATETIME_FORMAT)
          .format('DD MMM');
        if (date in mapping) {
          mapping[date].push(entry);
        } else {
          const newEntry = {};
          newEntry[date] = [entry];
          mapping = addToMapping(mapping, newEntry);
        }
      });
      setEntryDayMapping(mapping);
      setTrackerEntries(response);
    });
  };

  useEffect(() => {
    if (!selectedCompany) {
      return;
    }
    const fetchTrackerEntriesRequest = fetchUserTrackerEntries(
      selectedCompany.id,
      userProfile.id,
      history
    );
    fetchTrackerEntriesRequest().then((response) => {
      const runningEntryResponse = response.find((entry) => !entry.endsAt);

      if (runningEntryResponse) {
        const startsAt = moment.utc(
          runningEntryResponse.startsAt,
          MOMENT_DATETIME_FORMAT
        );
        const currentTime = moment.utc(
          moment.utc().format(MOMENT_DATETIME_FORMAT),
          MOMENT_DATETIME_FORMAT
        );
        setElapsedTime(calculateDateDiff(startsAt, currentTime));
        setRunningEntry(runningEntryResponse);
        createInterval();
      }

      let mapping = {};
      response.reverse();
      response.forEach((entry) => {
        const date = moment
          .utc(entry.startsAt, MOMENT_DATETIME_FORMAT)
          .format('DD MMM');
        if (date in mapping) {
          mapping[date].push(entry);
        } else {
          const newEntry = {};
          newEntry[date] = [entry];
          mapping = addToMapping(mapping, newEntry);
        }
      });
      setEntryDayMapping(mapping);

      setTrackerEntries(response);
    });

    return () => clearInterval(interval);
  }, []);

  const padNumber = (number) => number.toString().padStart(2, '0');

  const formatEntrySecondsDisplay = (nrOfSeconds) => {
    const hours = Math.floor(nrOfSeconds / 3600);
    const minutes = Math.floor(
      ((nrOfSeconds % 3600) - (nrOfSeconds % 60)) / 60
    );

    return `${padNumber(hours)}:${padNumber(minutes)}`;
  };

  const calculateTotalDuration = (entries) =>
    entries.reduce(
      (total, entry) =>
        total +
        calculateDateDiff(
          moment(entry.startsAt, MOMENT_DATETIME_FORMAT),
          moment(entry.endsAt, MOMENT_DATETIME_FORMAT)
        ),
      0
    );

  const handleTrackerStart = () => {
    if (runningEntry) {
      const updateTrackerEntryRequest = updateUserTrackEntry(
        selectedCompany.id,
        userProfile.id,
        runningEntry.id,
        history
      );
      updateTrackerEntryRequest({
        endsAt: moment.utc().format(MOMENT_DATETIME_FORMAT),
      }).then((response) => {
        const date = moment
          .utc(response.startsAt, MOMENT_DATETIME_FORMAT)
          .format('DD MMM');
        let mapping = entryDayMapping;
        if (date in entryDayMapping) {
          mapping[date].unshift(response);
        } else {
          const newEntry = {};
          newEntry[date] = [response];
          mapping = addToMapping(mapping, newEntry);
        }
        setEntryDayMapping(mapping);

        trackerEntries.unshift(response);
        setTrackerEntries(trackerEntries);
        clearInterval(interval);
        setRunningEntry(null);
        setElapsedTime(0);
      });
    } else {
      const createTrackerEntryRequest = createUserTrackEntry(
        selectedCompany.id,
        userProfile.id,
        history
      );
      createTrackerEntryRequest({
        startsAt: moment().utc().format(MOMENT_DATETIME_FORMAT),
      }).then((response) => {
        setRunningEntry(response);
        createInterval();
      });
    }
  };

  const handleEdit = () => {
    setIsEditing(!isEditing);
    setAddingEntry(false);
  };

  const addEntry = () => {
    setAddingEntry(true);
  };

  const handleNewEntryDate = (date) => {
    const startTime = moment(newEntryStartTime);
    const endTime = moment(newEntryEndTime);
    const newDate = moment(date);

    startTime.year(newDate.year()).month(newDate.month()).date(newDate.date());
    endTime.year(newDate.year()).month(newDate.month()).date(newDate.date());

    setNewEntryDate(newDate);
    setNewEntryStartTime(startTime);
    setNewEntryEndTime(endTime);
  };

  const handleNewEntryStartTime = (date) => {
    setNewEntryStartTime(date);
  };

  const handleNewEntryEndTime = (date) => {
    setNewEntryEndTime(date);
  };

  const cancelAddEntry = () => {
    setAddingEntry(false);
  };

  const handleAddEntry = () => {
    const createTrackerEntryRequest = createUserTrackEntry(
      selectedCompany.id,
      userProfile.id,
      history
    );

    createTrackerEntryRequest({
      startsAt: moment.utc(newEntryStartTime).format(MOMENT_DATETIME_FORMAT),
      endsAt: moment.utc(newEntryEndTime).format(MOMENT_DATETIME_FORMAT),
    }).then(fetchNewEntries());

    setAddingEntry(false);
    setNewEntryDate(moment());
    setNewEntryStartTime(moment({ hour: 8, minute: 0 }));
    setNewEntryEndTime(moment({ hour: 16, minute: 0 }));
  };

  const handleUpdateEntryStartTime = (date, id) => {
    const updateTrackerEntryRequest = updateUserTrackEntry(
      selectedCompany.id,
      userProfile.id,
      id,
      history
    );

    const endTime = moment(
      trackerEntries.find((entry) => entry.id === id).endsAt,
      MOMENT_DATETIME_FORMAT
    );
    if (endTime.diff(moment(date, MOMENT_DATETIME_FORMAT)) >= 0) {
      updateTrackerEntryRequest({
        startsAt: moment(date).utc().format(MOMENT_DATETIME_FORMAT),
      })
        .then(() => {
          setTimeError(null);
          fetchNewEntries();
        })
        .catch(() => setTimeError('Start time must be before end time'));
    }
  };

  const handleUpdateEntryEndTime = (date, id) => {
    const updateTrackerEntryRequest = updateUserTrackEntry(
      selectedCompany.id,
      userProfile.id,
      id,
      history
    );

    const startTime = moment(
      trackerEntries.find((entry) => entry.id === id).startsAt,
      MOMENT_DATETIME_FORMAT
    );
    if (moment(date, MOMENT_DATETIME_FORMAT).diff(startTime) >= 0) {
      updateTrackerEntryRequest({
        endsAt: moment(date).utc().format(MOMENT_DATETIME_FORMAT),
      })
        .then(() => {
          fetchNewEntries();
          setTimeError(null);
        })
        .catch(() => setTimeError('End time must be after start time'));
    }
  };

  const makeTrackerEntryDisplay = (trackerEntry) => {
    const startsAt = moment
      .utc(trackerEntry.startsAt, MOMENT_DATETIME_FORMAT)
      .utcOffset(utcOffset);
    const endsAt = moment
      .utc(trackerEntry.endsAt, MOMENT_DATETIME_FORMAT)
      .utcOffset(utcOffset);

    if (!isEditing)
      return `${startsAt.hour()}:${padNumber(
        startsAt.minute()
      )} - ${endsAt.hour()}:${padNumber(endsAt.minute())}`;

    return (
      <Grid container justify="space-between" className={styles.entryGrid}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <TimePicker
            margin="normal"
            ampm={false}
            label="Start Time"
            value={startsAt}
            onChange={(date) =>
              handleUpdateEntryStartTime(date, trackerEntry.id)
            }
          />
          <TimePicker
            margin="normal"
            ampm={false}
            label="End Time"
            value={endsAt}
            onChange={(date) => handleUpdateEntryEndTime(date, trackerEntry.id)}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    );
  };

  if (!selectedCompany) {
    return <NoCompanyFound />;
  }

  return (
    <div className={styles.trackerContainer}>
      {(!user || user.canEdit) && (
        <div className={styles.editRunningContainer}>
          <div className={styles.runningTrackerContainer}>
            <div className={styles.runningTrackerDisplay}>
              <div>{formatEntrySecondsDisplay(elapsedTime)}</div>
              <Button
                variant="contained"
                onClick={handleTrackerStart}
                color={runningEntry ? 'secondary' : 'primary'}
                classes={{ root: styles.startEntryButton }}
              >
                {runningEntry ? 'STOP' : 'START'}
              </Button>
            </div>
            {!runningEntry && !isEditing && (
              <Button
                variant="contained"
                className={styles.edit}
                onClick={handleEdit}
              >
                Edit <EditIcon />
              </Button>
            )}
            {!runningEntry && isEditing && (
              <Button
                variant="contained"
                className={styles.edit}
                onClick={handleEdit}
              >
                Done
              </Button>
            )}
          </div>
          {isEditing && !addingEntry && (
            <Button
              variant="contained"
              className={styles.addButton}
              onClick={addEntry}
            >
              + Add a new entry
            </Button>
          )}
          {addingEntry && (
            <div className={styles.newTrackerEntry}>
              <span>Add A New Entry</span>
              <Grid container justify="space-between">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    margin="normal"
                    id="startsAt"
                    label="Date"
                    format={DATE_FORMAT}
                    variant="inline"
                    value={newEntryDate}
                    onChange={handleNewEntryDate}
                  />
                  <TimePicker
                    margin="normal"
                    id="start-time-picker"
                    ampm={false}
                    label="Start Time"
                    value={newEntryStartTime}
                    onChange={handleNewEntryStartTime}
                  />
                  <TimePicker
                    margin="normal"
                    ampm={false}
                    id="end-time-picker"
                    label="End Time"
                    value={newEntryEndTime}
                    onChange={handleNewEntryEndTime}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <div className={styles.newEntryTotal}>
                <span>Total: </span>
                {formatEntrySecondsDisplay(
                  calculateDateDiff(
                    moment(newEntryStartTime, MOMENT_DATETIME_FORMAT),
                    moment(newEntryEndTime, MOMENT_DATETIME_FORMAT)
                  )
                )}
              </div>
              <div className={styles.newEntryButtons}>
                <Button
                  variant="contained"
                  onClick={handleAddEntry}
                  color="primary"
                >
                  Add
                </Button>
                <Button
                  variant="contained"
                  className={styles.edit}
                  onClick={cancelAddEntry}
                >
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
      {timeError && <Alert severity="error">{timeError}</Alert>}
      <div className={styles.trackerEntriesContainer}>
        {Object.keys(entryDayMapping).map((date) => (
          <div className={styles.trackerDateEntry}>
            <div className={styles.dateAndDuration}>
              <div className={styles.date}>{date}</div>
              <div className={styles.duration}>
                Total:{' '}
                {formatEntrySecondsDisplay(
                  calculateTotalDuration(entryDayMapping[date])
                )}
              </div>
            </div>
            {entryDayMapping[date].map(
              (entry) =>
                entry.endsAt && (
                  <div className={styles.trackerEntry} key={entry.id}>
                    <div>{makeTrackerEntryDisplay(entry)}</div>
                    <div className={styles.runningTrackerDisplay}>
                      {formatEntrySecondsDisplay(
                        calculateDateDiff(
                          moment(entry.startsAt, MOMENT_DATETIME_FORMAT),
                          moment(entry.endsAt, MOMENT_DATETIME_FORMAT)
                        )
                      )}
                      {isEditing && (
                        <ClearIcon
                          className={`${styles.edit} ${styles.clearIcon}`}
                          onClick={handleDelete(entry.id)}
                        />
                      )}
                    </div>
                  </div>
                )
            )}
          </div>
        ))}
      </div>
      <Modal open={confirmationOpen}>
        <div>Are you sure you want to delete this entry?</div>
        <br />
        <div>This action cannot be undone</div>
        <div className={styles.confirmationButtons}>
          <Button
            variant="contained"
            color="secondary"
            onClick={deleteEntry(deletingEntry)}
          >
            DELETE
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setConfirmationOpen(false)}
          >
            CANCEL
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default TrackerTimer;

import React from 'react';
import styles from './EventCard.module.scss';

const EventCard = ({ children, color, title, long }) => {
  return (
    <div
      className={styles.cardRoot}
      style={{
        borderTop: `5px solid ${color}`,
        height: long ? '510px' : '240px',
      }}
    >
      {title && <div className={styles.cardTitle}>{title}</div>}
      <div className={styles.cardContent}>{children}</div>
    </div>
  );
};

export default EventCard;

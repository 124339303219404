import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, TextField } from '@material-ui/core';
import { REQUIRED_FIELD_ERROR } from '../../../../constants/js-constants';
import FormErrors from '../../../common/FormErrors/FormErrors';
import Alert from '@material-ui/lab/Alert';
import styles from './ForgotPasswordForm.module.scss';
import { forgotPassword } from '../../../../services/api/api';

export const ForgotPasswordForm = () => {
  const [apiErrors, setApiErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const makeRequest = forgotPassword();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required(REQUIRED_FIELD_ERROR),
    }),
    onSubmit: (value) => {
      makeRequest(value)
        .then(() => setSubmitted(true))
        .catch((err) => setApiErrors(err));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        id="email"
        label="Email"
        type="email"
        variant="outlined"
        margin="normal"
        autoComplete="email"
        fullWidth
        required
        onChange={formik.handleChange}
        value={formik.values.email}
        onBlur={formik.onBlur}
      />
      <FormErrors formik={formik} fieldName="password" apiErrors={apiErrors} />
      <div className={styles.buttonContainer}>
        {submitted ? (
          <Alert severity="success">
            An email has been sent. Check your inbox.
          </Alert>
        ) : (
          <Button type="submit" variant="contained" color="primary">
            Email me
          </Button>
        )}
      </div>
    </form>
  );
};

export default ForgotPasswordForm;

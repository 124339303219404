import React, { useState, useRef } from "react";
import SignatureCanvas from 'react-signature-canvas';
import { uploadUserSignature, fetchUserSignature } from "../../../services/api/api";
/*
    This component opens a canva letting user to see their signature and change it
    file's saved as blob with name = user id
    add request from backend to obtain user signature if exists
*/
function DigitalSignature() {
    const [openModal, setOpenModal] = useState(false);
    const sigCanvas = useRef()

    const fetchSignature = async () => {
      try {
        let response = await fetchUserSignature();
        
        if (response && response.ok) {
          
          const blob = await response.blob();
          const imgUrl = URL.createObjectURL(blob);
    
          if (sigCanvas != null) {
            const canvas = sigCanvas.current?.getCanvas();
            if (canvas != null) {
              const ctx = canvas.getContext('2d');
              const img = new Image();
              img.onload = () => {
                ctx.drawImage(img, 0, 0);
              };
              img.src = imgUrl;
            }
          }
        } else {
          console.log("No signature found for user");
          if (sigCanvas != null) {
            sigCanvas.current.clear();
          }
        }
      } catch (error) {
        console.error("Error fetching user signature:", error);
        if (sigCanvas != null) {
          sigCanvas.current.clear();
        }
      }
    };
    
  
  
    const handleClear = () => {
      if(sigCanvas && sigCanvas.current)
        sigCanvas.current.clear()
    };
  
    const handleSave = async () => {
      const dataUrl = sigCanvas.current?.toDataURL("image/png");
      //setOpenModal(false);
      uploadUserSignature(dataUrl);
    };

    const openSignaturePad = () =>{
      if(openModal == false){
        fetchSignature();
      }
      setOpenModal(!openModal);
    }

    return (
        <div>
          <button onClick={openSignaturePad}> {openModal ? "Hide Signature" : "View Signature"}</button>
          {openModal && (
            <div className="modalContainer">
              <div className="modal">
                <div className="sigPadContainer" >
                  <SignatureCanvas 
                      penColor="black" 
                      canvasProps={{width: 375, height: 187, className: "sigCanvas"}}
                      ref={sigCanvas}
                  />
                </div>
                <br></br>
                <div className="modal__bottom">
                  <button onClick={handleSave}>Save</button>
                  &nbsp; &nbsp;
                  <button onClick={handleClear}>Clear</button>
                </div>
              </div>
            </div>
          )}
        </div>
      );
}

export default DigitalSignature;
import { createSlice } from '@reduxjs/toolkit';

export const choicesSlice = createSlice({
  name: 'choices',
  initialState: {
    companySizeChoices: [],
    countryChoices: [],
  },
  reducers: {
    updateCompanySizeChoices: (state, action) => {
      state = state || {};
      state.companySizeChoices = action.payload;
      return state;
    },
    updateCountryChoices: (state, action) => {
      state = state || {};
      state.countryChoices = action.payload;
      return state;
    },
    updateTimeOffTypeChoices: (state, action) => {
      state = state || {};
      state.timeOffTypeChoices = action.payload;
      return state;
    },
  },
});

export const {
  updateCountryChoices,
  updateCompanySizeChoices,
  updateTimeOffTypeChoices,
} = choicesSlice.actions;

export const companySizeChoicesSelector = (state) =>
  state.choices ? state.choices.companySizeChoices : [];
export const countryChoicesSelector = (state) =>
  state.choices ? state.choices.countryChoices : [];
export const timeOffTypeChoicesSelector = (state) =>
  state.choices ? state.choices.timeOffTypeChoices : [];

export default choicesSlice.reducer;

import React, { useEffect, useState, useRef } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Avatar as MaterialAvatar, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import styles from './Avatar.module.scss';
import { makeFileUrl } from '../../../services/api/urls';
import { fetchFile, uploadFile } from '../../../services/api/api';
import Modal from '../Modal/Modal';

const Avatar = ({
  name,
  url,
  fileUploadUrl,
  width,
  height,
  initials,
  callback,
  fieldName,
}) => {
  const [fileSrc, setFileSrc] = useState(null);
  const [croppedData, setCroppedData] = useState(null);
  const [cropperModalOpen, setCropperModalOpen] = useState(false);
  const history = useHistory();
  const cropperRef = useRef(null);

  const makeFetchFileRequest = fetchFile(url, history);
  let makeUploadFileRequest;
  callback = callback || (() => {});

  if (fileUploadUrl) {
    makeUploadFileRequest = uploadFile(fileUploadUrl, 'PATCH', true);
  }

  useEffect(() => {
    if (url) {
      makeFetchFileRequest().then((response) => setFileSrc(response));
    }
  }, []);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    const fileUrl = URL.createObjectURL(file);
    setFileSrc(fileUrl);
    setCropperModalOpen(true);
  };

  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    const canvas = cropper.getCroppedCanvas();
    if (canvas !== null) {
      canvas.toBlob((blob) => setCroppedData(blob));
    }
  };

  const handleModalClose = () => {
    setCropperModalOpen(!cropperModalOpen);
    const url = URL.createObjectURL(croppedData);
    setFileSrc(url);
    const dataToSend = {};
    dataToSend[fieldName] = croppedData;
    makeUploadFileRequest(dataToSend).then((response) =>
      callback(response[fieldName])
    );
  };

  let nameInitials;
  if (initials) {
    nameInitials = name
      .split(' ')
      .reduce(
        (initial, nameSection) => initial.concat(nameSection.charAt(0)),
        ''
      );
  }

  const style = {};
  if (width) {
    style.width = width;
  }
  if (height) {
    style.height = height;
  }

  return (
    <>
      <Modal open={cropperModalOpen} onClose={handleModalClose}>
        <Cropper
          src={fileSrc}
          style={{ height: 400, width: '100%' }}
          initialAspectRatio="1"
          guides={false}
          crop={onCrop}
          ref={cropperRef}
        />
        <div className={styles.saveButtonContainer}>
          <Button
            onClick={handleModalClose}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </div>
      </Modal>
      <div className={styles.avatarContainer}>
        <MaterialAvatar
          src={fileSrc}
          alt={`${name}-avatar`}
          className={styles.avatar}
          style={style}
        >
          {nameInitials || name}
        </MaterialAvatar>
        {fileUploadUrl && (
          <div className={styles.uploadButton} style={{ width }}>
            <label htmlFor="fileUpload" className={styles.upload} />
            <input
              id="fileUpload"
              type="file"
              className={styles.originalInput}
              onChange={handleFileUpload}
            />
          </div>
        )}
      </div>
    </>
  );
};

Avatar.propTypes = {};

Avatar.defaultProps = {};

export default Avatar;

import React, { useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button } from '@material-ui/core';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import styles from './EmployeeTimeOff.module.scss';
import Modal from '../../../common/Modal/Modal';
import EditTimeoffForm from '../../MyTimeoffManagement/EditTimeoffForm/EditTimeoffForm';
import TimeOffListItem from '../../../common/TimeOffListItem/TimeOffListItem';
import {
  editTimeOff,
  deleteTimeOff,
  updateUser,
  makeFormDataRequest,
} from '../../../../services/api/api';

const EmployeeTimeOff = ({ employee, companyId, callback }) => {
  const [isEditingRemaining, setIsEditingRemaining] = useState(false);
  const [isEditingTotal, setIsEditingTotal] = useState(false);
  const [editTimeoffModalOpen, setEditTimeOffModalOpen] = useState(false);
  const [editablePeriod, setEditablePeriod] = useState({});
  const [timeOff, setTimeOff] = useState(employee.timeOff);
  const [timeOffDaysRemaining, setTimeOffDaysRemaining] = useState(
    employee.timeOffDaysRemaining
  );
  const [timeOffDaysTotal, setTimeOffDaysTotal] = useState(
    employee.timeOffDaysTotal
  );
  const history = useHistory();
  const makeUpdateRequest = updateUser(companyId, employee.id, history);

  if (isEditingTotal) {
    const totalDaysInput = document.getElementById('totalDaysInput');
    if (totalDaysInput) {
      totalDaysInput.focus();
    }
  }

  const handleRemainingDaysInput = (event) => {
    const value = event.target.value
      ? event.target.value
          .split('')
          .filter((char) => !isNaN(char))
          .join('')
      : 0;
    setTimeOffDaysRemaining(Number(value));
  };

  const handleTotalDaysInput = (event) => {
    const value = event.target.value
      ? event.target.value
          .split('')
          .filter((char) => !isNaN(char))
          .join('')
      : 0;
    setTimeOffDaysTotal(Number(value));
  };

  const makeEditTimeOffRequest = (period) =>
    makeFormDataRequest(
      editTimeOff(
        companyId,
        period.id,
        history,
        'application/json'
      ),
      'PATCH'
    );
  const makeDeleteTimeOffRequest = (period) =>
    deleteTimeOff(companyId, period.id, history);

  const handleTimeOffDelete = (period) => () => {
    const deleteTimeOffRequest = makeDeleteTimeOffRequest(period);
    deleteTimeOffRequest().then(() => {
      const updatedTimeOff = timeOff.filter(
        (timeOffPeriod) => timeOffPeriod.id !== period.id
      );
      const newTimeOffDaysRemaining = timeOffDaysRemaining + period.duration;
      employee.timeOffDaysRemaining = newTimeOffDaysRemaining;
      employee.timeOff = updatedTimeOff;
      setTimeOff(updatedTimeOff);
      setTimeOffDaysRemaining(newTimeOffDaysRemaining);
      callback(employee);
    });
  };

  const updateTimeOff = (updatedTimeOff) => {
    const newTimeOff = timeOff.map((period) =>
      updatedTimeOff.id === period.id ? updatedTimeOff : period
    );
    employee.timeOff = newTimeOff;
    setTimeOff(newTimeOff);
    callback(employee);
  };

  const handleEditModalOpen = (period) => () => {
    setEditTimeOffModalOpen(true);
    setEditablePeriod(period);
  };
  const handleEditModalClose = () => setEditTimeOffModalOpen(false);

  const handleBlur = () => {
    const diff = employee.timeOffDaysTotal - timeOffDaysTotal;
    employee.timeOffDaysTotal = timeOffDaysTotal;
    employee.timeOffDaysRemaining -= diff;

    setIsEditingRemaining(false);
    setIsEditingTotal(false);
    setTimeOffDaysRemaining(employee.timeOffDaysRemaining);
    makeUpdateRequest({
      timeOffDaysRemaining,
      timeOffDaysTotal,
    }).then((employeeResponse) => callback(employeeResponse));
  };

  const upcomingTimeOff = [];
  const pastTimeOff = [];
  if (employee && timeOff) {
    timeOff.forEach((period) => {
      const endsAt = moment(period.endsAt, 'DD/MM/YYYY');
      if (moment().diff(endsAt) >= 0) {
        pastTimeOff.push(period);
      } else {
        upcomingTimeOff.unshift(period);
      }
    });
  }

  return (
    <>
      <div className={styles.summaryContainer}>
        <div className={styles.summary}>
          <h4>Remaining days</h4>
          {employee.canEdit && isEditingRemaining ? (
            <input
              type="text"
              placeholder={timeOffDaysRemaining}
              value={timeOffDaysRemaining}
              onChange={handleRemainingDaysInput}
              onBlur={handleBlur}
              className={styles.timeOffDaysRemainingInput}
            />
          ) : (
            <div className={styles.remainingDays}>
              <b id="remainingDaysInput">{timeOffDaysRemaining}</b>
              {employee.canEdit && !isEditingRemaining && (
                <div onClick={() => setIsEditingRemaining(true)}></div>
              )}
            </div>
          )}
        </div>
        <div className={styles.summary}>
          <h4>Total days</h4>
          {employee.canEditTimeOff && isEditingTotal ? (
            <input
              id="totalDaysInput"
              type="text"
              placeholder={timeOffDaysTotal}
              value={timeOffDaysTotal}
              onChange={handleTotalDaysInput}
              onBlur={handleBlur}
              className={styles.timeOffDaysRemainingInput}
            />
          ) : (
            <div className={styles.remainingDays}>
              <b id="remainingDaysInput">{timeOffDaysTotal}</b>
              {employee.canEdit && employee.canEditTimeOff && !isEditingTotal && (
                <div
                  className={styles.editIcon}
                  onClick={() => setIsEditingTotal(true)}
                >
                  <EditIcon />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <h3 className={styles.upcomingTimeOff}>Upcoming time off: </h3>
      <div className={styles.timeOffList}>
        {!upcomingTimeOff.length && <p>No upcoming time off.</p>}
        {upcomingTimeOff.map((period) => (
          <div key={period.id}>
            <div className={styles.periodContainer}>
              <TimeOffListItem timeOff={period} />
              {employee.canEditTimeOff && (
                <div className={styles.actionsContainer}>
                  <Button
                    variant="contained"
                    className={styles.edit}
                    onClick={handleEditModalOpen(period)}
                  >
                    Edit <EditIcon />
                  </Button>
                  <Button
                    variant="contained"
                    className={styles.edit}
                    onClick={handleTimeOffDelete(period)}
                  >
                    Delete <DeleteIcon />
                  </Button>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <h3 className={styles.upcomingTimeOff}>Past time off: </h3>
      <div className={styles.timeOffList}>
        {!pastTimeOff.length && <p>No past time off.</p>}
        {pastTimeOff.map((period) => (
          <div key={period.id}>
            <div className={styles.periodContainer}>
              <TimeOffListItem timeOff={period} />
              {employee.canEditTimeOff && (
                <div className={styles.actionsContainer}>
                  <Button
                    variant="contained"
                    className={styles.edit}
                    onClick={handleEditModalOpen(period)}
                  >
                    Edit <EditIcon />
                  </Button>
                  <Button
                    variant="contained"
                    className={styles.edit}
                    onClick={handleTimeOffDelete(period)}
                  >
                    Delete <DeleteIcon />
                  </Button>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <Modal
        open={editTimeoffModalOpen}
        onClose={handleEditModalClose}
        title="Edit Time Off"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <EditTimeoffForm
          closeModal={handleEditModalClose}
          initialValues={editablePeriod}
          submitRequest={makeEditTimeOffRequest(editablePeriod)}
          updateTimeOff={updateTimeOff}
        />
      </Modal>
    </>
  );
};

export default EmployeeTimeOff;
